import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, Input, message } from 'antd';
import { Paper, Modal, Label } from '../Common';
import SelectBranch from './SelectBranch';
import SelectAimag from '../Branch/SelectAimag';
import SelectDistrict from '../Branch/SelectDistrict';
import { uploadImages } from '../Common/utils';
import ImageUploadOne from '../Common/ImageUploadOne';
import { FlexRow } from '../Common/Styled';
import StaffList from './StaffList';

const { TextArea } = Input;

const ADD_TASK = gql`
  mutation createTask(
    $branchId: Int!
    $isEmergency: Boolean!
    $description: String!
    $images: [ImageInput]
  ) {
    createTask(
      input: {
        branchId: $branchId
        description: $description
        isEmergency: $isEmergency
        images: $images
      }
    ) {
      ok
      message
    }
  }
`;

const DELETE_TASK = gql`
  mutation deleteBranch($id: Int!) {
    deleteBranch(id: $id) {
      ok
      message
    }
  }
`;

interface TaskInputType {
  id?: string;
  aimagId?: string;
  districtId?: string;
  branchId?: string;
  description?: string;
  images?: ImageType[];
  isEmergency?: boolean;
}

function ModalCreate({
  selected,
  visible,
  onOk,
  isEmergency,
  onCancel,
}: ModalProps<Task>): React.ReactElement {
  const [uniqueId, setUniqueId] = useState(0);
  const [saving, setSaving] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [dump, setDump] = useState({});
  const [doc, setDoc] = useState<TaskInputType>({});
  const [createTask, { error }] = useMutation(ADD_TASK);
  const [actionDeleteMutation] = useMutation(DELETE_TASK);

  React.useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const onSubmit = () => {
    const params = { ...doc };

    if (selected) {
      params.id = selected.id;
    }

    setSaving(true);
    const uploads = Object.values(dump).filter((v) => v) as S3ImageUploadType[];
    uploadImages(uploads).then((results) => {
      createTask({ variables: { ...params, images: results } })
        .then(() => {
          message.success('Захиалга амжилттай бүртгэгдлээ');
          setPristine(true);
          setUniqueId(window.performance.now());
          setSaving(false);
          onOk();
        })
        .catch((raw) => {
          const parsedError = JSON.parse(JSON.stringify(raw));
          message.error(parsedError.message);
          setSaving(false);
        });
    });
  };

  const onDelete = (item: Task) => {
    actionDeleteMutation({ variables: { id: item.id } })
      .then((k) => {
        const lastRecord = item?.taskDetails?.slice(-1)[0];

        message.success(`${lastRecord?.note} ${k.data.deleteTask.message}`);
        onOk();
      })
      .catch((raw) => {
        const parsedError = JSON.parse(JSON.stringify(raw));
        message.error(parsedError.message);
      });
  };

  const setDocValue = (name: string, value: string | number) => {
    setDoc({ ...doc, [name]: value });
    setPristine(false);
  };

  const onCloseModal = () => {
    setPristine(true);
    setUniqueId(window.performance.now());
    onCancel();
  };

  React.useEffect(() => {
    setDoc({
      ...doc,
      description: '',
      aimagId: '',
      districtId: '',
      branchId: '',
      isEmergency: isEmergency || false,
    });
  }, [visible, selected?.id]);

  return (
    <Modal
      width={510}
      key={`task-${selected?.id}`}
      title={
        isEmergency
          ? 'Ослын дуудлага'
          : `Захиалга ${selected?.id ? 'засах' : 'нэмэх'}`
      }
      pristine={pristine}
      visible={visible}
      onOk={onSubmit}
      onCancel={onCloseModal}
      onDelete={onDelete}
      item={selected}
      saving={saving}
      values={[
        { name: 'description', value: doc.description },
        { name: 'branch', value: doc.branchId },
      ]}
      submitText="Хадгалах">
      <Paper style={{ paddingTop: 10 }}>
        {isEmergency && <StaffList />}
        <FlexRow>
          <div style={{ flex: 1, marginRight: 20 }}>
            <Label>Харъяалал</Label>
            <SelectAimag
              allowClear
              value={doc?.aimagId}
              onChange={(aimagId: string) => {
                setDoc({ ...doc, aimagId, districtId: '', branchId: '' });
              }}
              required
            />
          </div>
          <div style={{ flex: 1 }}>
            <Label>Байршил</Label>
            <SelectDistrict
              allowClear
              value={doc?.districtId}
              aimagId={doc?.aimagId}
              onChange={(districtId: string) => {
                setDoc({ ...doc, districtId, branchId: '' });
              }}
              required
            />
          </div>
        </FlexRow>
        <Label>Салбар</Label>
        <Form.Item
          name="branch"
          rules={[{ required: true, message: 'Заавал cонгоно уу!' }]}>
          <SelectBranch
            className="required"
            onChange={(branchId: string) => setDoc({ ...doc, branchId })}
            aimagId={doc.aimagId}
            districtId={doc.districtId}
            required
          />
        </Form.Item>
        <Label>Ажлын тайлбар</Label>
        <Form.Item
          name="description"
          rules={[{ required: true, message: 'Заавал cонгоно уу!' }]}>
          <TextArea
            rows={6}
            className="required"
            value={doc?.description}
            onChange={(e) => setDocValue('description', e.currentTarget.value)}
          />
        </Form.Item>
        <Label>Зураг оруулах</Label>
        {[0, 1].map((i) => (
          <ImageUploadOne
            key={i + uniqueId}
            onChooseImage={(imageObj) => {
              setPristine(false);
              setDump({ ...dump, [i]: imageObj });
            }}
          />
        ))}
      </Paper>
    </Modal>
  );
}

export default ModalCreate;
