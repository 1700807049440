import { gql, useMutation } from '@apollo/client';
import { Input, message, Radio } from 'antd';
import React, { useState } from 'react';
import { Label, Modal, Paper } from '../Common';
import { FlexColumn, FlexRow } from '../Common/Styled';
import { addSeparator } from '../Common/utils';
import TaskInfo, { Bold, Padded } from './TaskInfo';

const { TextArea } = Input;

const APPROVE_TASK = gql`
  mutation approveTask($id: Int!, $approved: Boolean!, $approveNote: String) {
    approveTask(
      id: $id
      input: { approved: $approved, approveNote: $approveNote }
    ) {
      ok
      message
    }
  }
`;

type ApprovalType = {
  approved?: boolean;
  approveNote?: string;
};

function ModalAccept({
  selected,
  visible,
  onOk,
  onCancel,
}: ModalProps<Task>): React.ReactElement {
  const [saving, setSaving] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [doc, setDoc] = useState<ApprovalType>({ approved: true });
  const [approveTask, { error }] = useMutation(APPROVE_TASK);

  const lastRecord = selected?.taskDetails?.slice(-1)[0];

  React.useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const onSubmit = () => {
    setSaving(true);
    approveTask({ variables: { id: selected?.id, ...doc } })
      .then(() => {
        message.success('Амжилттай бүртгэгдлээ');
        setSaving(false);
        onOk();
      })
      .catch((raw) => {
        const parsedError = JSON.parse(JSON.stringify(raw));
        message.error(parsedError.message);
        setSaving(false);
      });
  };

  const setDocValue = (name: string, value: string) => {
    setDoc({ ...doc, [name]: value });
    setPristine(false);
  };

  const onCloseModal = () => {
    setPristine(true);
    onCancel();
  };

  React.useEffect(() => {
    setDoc({
      ...doc,
      approved: selected?.approved,
      approveNote: selected?.approveNote,
    });
  }, [visible, selected?.id]);

  return (
    <Modal
      width={700}
      key={`approval-${selected?.id}`}
      title="Ажил хүлээлцэх"
      pristine={pristine}
      visible={visible}
      onOk={onSubmit}
      canDelete={false}
      onCancel={onCloseModal}
      item={selected}
      saving={saving}
      submitText="Хадгалах">
      <TaskInfo selected={selected}>
        <FlexRow>
          <FlexColumn style={{ width: 130 }}>
            <Bold>Тэмдэглэл</Bold>
            <Bold>Зардлын дүн</Bold>
          </FlexColumn>
          <FlexColumn>
            <Padded>{lastRecord?.note || '-'}</Padded>
            <Padded>{addSeparator(selected?.totalExpense)} төгрөг</Padded>
          </FlexColumn>
        </FlexRow>
      </TaskInfo>
      <Paper style={{ paddingTop: 10 }}>
        <Label>Гүйцэтгэлийг хүлээн авах эсэх</Label>
        <Radio.Group
          onChange={(e) => setDocValue('approved', e.target.value)}
          optionType="button"
          buttonStyle="solid"
          value={doc.approved}>
          <Radio.Button value>Тийм</Radio.Button>
          <Radio.Button value={false}>Үгүй</Radio.Button>
        </Radio.Group>
        <Label>Тайлбар</Label>
        <TextArea
          rows={4}
          value={doc.approveNote}
          onChange={(e) => setDocValue('approveNote', e.currentTarget.value)}
        />
      </Paper>
    </Modal>
  );
}

export default ModalAccept;
