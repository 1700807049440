import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useMutation, gql } from '@apollo/client';
import { Form, Input, InputNumber, message } from 'antd';
import { Paper, Modal, Label } from '../Common';
import TaskInfo from './TaskInfo';
import SelectWorkCategory from './SelectWorkCategory';
import SelectStatus from './SelectStatus';
import { FlexRow } from '../Common/Styled';
import { uploadImages, renderDateFull } from '../Common/utils';
import TimePicker from '../Common/TimePicker';
import ImageUploadOne from '../Common/ImageUploadOne';

const { TextArea } = Input;

export const BRANCH_TYPE = [
  {
    id: 1,
    name: 'Тооцооны төв',
  },
  {
    id: 2,
    name: 'Экспресс банк',
  },
  {
    id: 3,
    name: 'АТМ',
  },
  {
    id: 4,
    name: 'Зөөврийн АТМ',
  },
  {
    id: 9,
    name: 'Бусад',
  },
];

const UPDATE_TASK_STATUS = gql`
  mutation updateTaskStatus(
    $id: Int!
    $workCategoryIds: String!
    $status: Int!
    $startedAt: DateTime
    $finishedAt: DateTime
    $note: String
    $numofPr: Int
    $teamId: Int
    $images: [ImageInput]
  ) {
    updateTaskStatus(
      id: $id
      input: {
        workCategoryIds: $workCategoryIds
        status: $status
        startedAt: $startedAt
        finishedAt: $finishedAt
        note: $note
        numofPr: $numofPr
        teamId: $teamId
        images: $images
      }
    ) {
      ok
      message
    }
  }
`;

interface TaskInputType {
  id?: string;
  note?: string;
  status?: number;
  workCategoryIds?: string[];
  startedAt?: Date | null;
  finishedAt?: Date | null;
  teamId?: string;
  numofPr?: number;
}

function ModalTimeState({
  selected,
  visible,
  onOk,
  onCancel,
}: ModalProps<Task>): React.ReactElement {
  const [pristine, setPristine] = useState(true);
  const [saving, setSaving] = useState(false);
  const [dump, setDump] = useState({});
  const [isStartOpen, setIsStartOpen] = useState<boolean>(false);
  const [isFinishOpen, setIsFinishOpen] = useState<boolean>(false);
  const [doc, setDoc] = useState<TaskInputType>({});
  const [startDate, setStartDate] = useState<Date | null>();
  const [finishDate, setFinishDate] = useState<Date | null>();
  const [actionMutation, { error }] = useMutation(UPDATE_TASK_STATUS);

  const lastRecord = selected?.taskDetails?.slice(-1)[0];

  React.useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const onSubmit = () => {
    const params = { ...doc, workCategoryIds: doc.workCategoryIds?.join(',') };

    if (selected) {
      params.id = selected.id;
      params.startedAt = startDate;
      params.finishedAt = finishDate;
    }

    setSaving(true);
    const uploads = Object.values(dump).filter((v) => v) as S3ImageUploadType[];
    uploadImages(uploads).then((results) => {
      actionMutation({ variables: { ...params, images: results } })
        .then((res) => {
          if (!res.data?.updateTaskStatus.ok) {
            message.error(res.data?.updateTaskStatus.message);
            setSaving(false);
            return;
          }
          message.success('Захиалга амжилттай бүртгэгдлээ');
          setPristine(true);
          setSaving(false);
          onOk();
        })
        .catch((raw) => {
          const parsedError = JSON.parse(JSON.stringify(raw));
          message.error(parsedError.message);
        });
    });
  };

  const setDocValue = (
    name: string,
    value: string | number | Date | string[],
  ) => {
    setDoc({ ...doc, [name]: value });
    setPristine(false);
  };

  React.useEffect(() => {
    setDoc({
      note: lastRecord?.note || '',
      status: selected?.status,
      workCategoryIds: selected?.workCategories?.map((v) => v.id),
      numofPr: lastRecord?.numofPr || 1,
    });
    setStartDate(
      lastRecord?.startedAt
        ? new Date(renderDateFull(lastRecord?.startedAt))
        : undefined,
    );
    setFinishDate(
      lastRecord?.finishedAt
        ? new Date(renderDateFull(lastRecord?.finishedAt))
        : undefined,
    );
  }, [visible, selected?.id]);

  const handleDisabledHours = (): number[] => {
    if (startDate) {
      return Array.from(Array(startDate?.getHours()).keys());
    }
    return [];
  };

  const handleDisabledMinutes = (hour: number): number[] => {
    if (startDate && startDate.getHours() === hour) {
      return Array.from(Array((startDate.getMinutes() || 0) + 1).keys());
    }
    return [];
  };

  const onTimeChange =
    (setValue: (date: Date | null) => void) => (time: dayjs.Dayjs | null) => {
      setValue(time ? time.toDate() : null);
      setPristine(false);
    };

  const onCloseModal = () => {
    setPristine(true);
    setDoc({});
    onCancel();
  };

  return (
    <Modal
      key={`task-${selected?.id}`}
      title="Цаг төлөв өөрчлөх"
      width={700}
      visible={visible}
      onOk={onSubmit}
      onCancel={onCloseModal}
      canDelete={false}
      pristine={pristine}
      saving={saving}
      item={selected}
      values={[
        { name: 'note', value: doc.note },
        {
          name: 'workCategoryIds',
          value: doc?.workCategoryIds,
        },
      ]}
      submitText="Хадгалах">
      <TaskInfo selected={selected} />
      <Paper style={{ paddingTop: 10 }}>
        <Label>Ажилбар</Label>
        <Form.Item
          name="workCategoryIds"
          rules={[
            { required: true, type: 'array', message: 'Заавал cонгоно уу!' },
          ]}>
          <SelectWorkCategory
            className="required"
            onChange={(value: string[]) => {
              setDocValue('workCategoryIds', value);
            }}
            required
          />
        </Form.Item>
        <FlexRow>
          <div style={{ flex: 7 }}>
            <FlexRow>
              <div style={{ flex: 2, marginRight: 20 }}>
                <Label>Төлөв</Label>
                <SelectStatus
                  defaultValue={selected?.status}
                  onChange={(value: number) => {
                    setDocValue('status', value);
                  }}
                  required
                />
                <Label>Хүний тоо</Label>
                <InputNumber
                  style={{ width: '100%' }}
                  value={doc?.numofPr}
                  min={1}
                  onChange={(numofPr) => {
                    setDoc({ ...doc, numofPr });
                    message.destroy();
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Label>Эхэлсэн цаг</Label>
                <TimePicker
                  showNow={false}
                  open={isStartOpen}
                  format="HH:mm"
                  onChange={onTimeChange(setStartDate)}
                  onSelect={onTimeChange(setStartDate)}
                  value={startDate ? dayjs(startDate.toString()) : undefined}
                  placeholder=""
                  style={{ width: '100%' }}
                  onKeyDown={(e) => {
                    if (e.code === 'Escape' && isStartOpen) {
                      e.stopPropagation();
                      setIsStartOpen(false);
                    }
                  }}
                  onClick={() => {
                    setIsStartOpen(true);
                    setStartDate(new Date());
                  }}
                  onOk={() => setIsStartOpen(false)}
                  onBlur={() => setIsStartOpen(false)}
                />
                <Label>Дууссан цаг</Label>
                <TimePicker
                  showNow={false}
                  onChange={onTimeChange(setFinishDate)}
                  onSelect={onTimeChange(setFinishDate)}
                  value={finishDate ? dayjs(finishDate) : undefined}
                  placeholder=""
                  disabledHours={handleDisabledHours}
                  disabledMinutes={handleDisabledMinutes}
                  format="HH:mm"
                  hideDisabledOptions
                  style={{ width: '100%' }}
                  onKeyDown={(e) => {
                    if (e.code === 'Escape' && isFinishOpen) {
                      e.stopPropagation();
                      setIsFinishOpen(false);
                    }
                  }}
                  onClick={() => {
                    setIsFinishOpen(true);
                    setFinishDate(new Date());
                  }}
                  onOk={() => setIsFinishOpen(false)}
                  onBlur={() => setIsStartOpen(false)}
                />
              </div>
            </FlexRow>
            <div>
              <Label>Зураг оруулах</Label>
              {[0, 1].map((i) => (
                <ImageUploadOne
                  key={i}
                  onChooseImage={(imageObj) => {
                    setPristine(false);
                    setDump({ ...dump, [i]: imageObj });
                  }}
                />
              ))}
            </div>
          </div>
          <div style={{ flex: 6, marginLeft: 23 }}>
            <Label>Тэмдэглэл</Label>
            <TextArea
              rows={10}
              defaultValue={lastRecord?.note || ''}
              onChange={(e) => setDocValue('note', e.currentTarget.value)}
            />
          </div>
        </FlexRow>
      </Paper>
    </Modal>
  );
}

export default ModalTimeState;
