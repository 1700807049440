import React from 'react';
import GoogleMapReact from 'google-map-react';
import { InputNumber } from 'antd';
import { BankFilled } from '@ant-design/icons';
import { Label } from '../Common';
import { FlexColumn, FlexRow } from '../Common/Styled';
import { extractLocation } from '../Common/utils';

type MarkerProps = {
  lat?: number;
  lng?: number;
};

const Marker = ({ lat, lng }: MarkerProps) => (
  <div style={{ position: 'relative' }} title={`${lat}:${lng}`}>
    <div style={{ left: -10, top: -15, position: 'absolute' }}>
      <BankFilled style={{ fontSize: 20, color: '#030e22' }} />
    </div>
  </div>
);

type Props = {
  branch?: Branch;
  onChange: (value: string) => void;
};

function MapControl({ branch, onChange }: Props): React.ReactElement {
  const [lat, setLat] = React.useState<number>();
  const [lng, setLng] = React.useState<number>();

  const defaultProps = {
    center: {
      lat: lat || 47.914,
      lng: lng || 106.91,
    },
    zoom: 12,
  };

  React.useEffect(() => {
    if (branch?.gpsCoordinat) {
      const [a, b] = extractLocation(branch?.gpsCoordinat);
      setLat(a);
      setLng(b);
    }
  }, [branch]);

  return (
    <FlexColumn>
      <FlexRow>
        <div style={{ flex: 1, margin: '0 10px 10px 0' }}>
          <Label>Уртраг</Label>
          <InputNumber
            style={{ width: '100%' }}
            value={lat}
            onChange={setLat}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Label>Өргөрөг</Label>
          <InputNumber
            style={{ width: '100%' }}
            value={lng}
            onChange={setLng}
          />
        </div>
      </FlexRow>
      <div style={{ height: 425 }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyCC5vhwoxOo3WE2GtipapOG1mWad1Y93jA',
            language: 'mn',
          }}
          onClick={(e) => {
            setLat(e.lat);
            setLng(e.lng);
            onChange(`${e.lat}:${e.lng}`);
          }}
          center={defaultProps.center}
          zoom={defaultProps.zoom}>
          {lat && lng && <Marker lat={lat} lng={lng} />}
        </GoogleMapReact>
      </div>
    </FlexColumn>
  );
}

export default MapControl;
