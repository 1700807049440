import React from 'react';
import { differenceInHours } from 'date-fns';
import { Tag, Tooltip } from 'antd';
import { formatInTimeZone } from 'date-fns-tz';

export const BRANCH_TYPE = [
  {
    id: 1,
    name: 'Тооцооны төв',
  },
  {
    id: 2,
    name: 'Экспресс банк',
  },
  {
    id: 3,
    name: 'АТМ',
  },
  {
    id: 4,
    name: 'Зөөврийн АТМ',
  },
  {
    id: 9,
    name: 'Бусад',
  },
];

type TaskStatusType = {
  id: number;
  name: string;
};

export const TASK_STATUS: TaskStatusType[] = [
  {
    id: 0,
    name: 'Хийх',
  },
  {
    id: 1,
    name: 'Хийсэн',
  },
  {
    id: 2,
    name: 'Хийгдэж байгаа',
  },
  {
    id: 9,
    name: 'Буцаах',
  },
];

export const taskColumnsInExcel = [
  {
    title: 'Id',
    dataIndex: 'id',
    width: 50,
  },
  {
    title: 'Бүртгэсэн',
    dataIndex: 'registeredAt',
    width: 130,
  },
  {
    title: 'Салбар',
    dataIndex: ['branch', 'name'],
  },
  {
    title: 'Харьяалал',
    dataIndex: ['branch', 'aimag', 'name'],
  },
  {
    title: 'Байршил',
    dataIndex: ['branch', 'district', 'name'],
  },
  {
    title: 'Ажлын тайлбар',
    dataIndex: 'description',
  },
  {
    title: 'Эхэлсэн',
    dataIndex: 'startedAt',
    width: 130,
  },
  {
    title: 'Дууссан',
    dataIndex: 'finishedAt',
    width: 130,
  },
  {
    title: 'Оруулсан',
    dataIndex: ['user', 'loginName'],
    width: 90,
  },
  {
    title: 'Төлөв',
    dataIndex: 'status',
    width: 40,
  },
];

export const renderBranch = (task: Task | undefined): React.ReactNode => {
  return (
    <div
      title={`${task?.branch?.aimag?.name}, ${task?.branch?.district?.name}`}>
      <div>{task?.branch?.name}</div>
      <div
        style={{
          fontSize: 12,
          color: 'grey',
        }}>{`${task?.branch?.aimag?.name}, ${task?.branch?.district?.name}`}</div>
    </div>
  );
};

export const renderCreatedBy = (task: Task | undefined): React.ReactNode => {
  const managerInfo = [];
  if (task?.branch?.managerName) {
    managerInfo.push(task?.branch?.managerName);
  }

  if (task?.branch?.phone) {
    managerInfo.push(task?.branch?.phone);
  }

  return (
    <div title={managerInfo.join(', ')}>
      <div>{managerInfo.join(', ')}</div>
      <div
        style={{
          fontSize: 12,
          color: 'grey',
        }}>
        {task?.user?.loginName}
      </div>
    </div>
  );
};

export const renderStatus = (task: TaskHistory): React.ReactNode => {
  const status = TASK_STATUS.find((v) => v.id === task?.status);
  return (
    <div>
      <div>{status ? status.name : ''}</div>
      {!!task?.isEmergency && <Tag color="red">Яаралтай</Tag>}
    </div>
  );
};

export const ACTIONS = ['APPROVE', 'STATUS', 'EXPENSE', 'CREATE'];

export const renderNote = (taskHistory: TaskHistory): React.ReactNode => {
  let value;
  if (taskHistory.action === ACTIONS[0]) {
    value = taskHistory.approveNote;
  } else if (taskHistory.action === ACTIONS[1]) {
    value = taskHistory.taskDetail?.note;
  } else if (taskHistory.action === ACTIONS[2]) {
    value = taskHistory.expenseNote;
  } else {
    value = taskHistory.description;
  }

  return <div>{value}</div>;
};

export const getTaskContinuedDays = (task?: Task): React.ReactNode => {
  if (task && task.registeredAt) {
    const continuedHours = Math.max(
      1,
      differenceInHours(
        task.maxFinishedAt
          ? new Date(`${task.maxFinishedAt}.000Z`)
          : new Date(),
        new Date(`${task.registeredAt}.000Z`),
      ),
    );
    const continuedDays = Math.ceil(continuedHours / 24);
    return (
      <Tooltip title="Үргэлжилсэн өдрийн тоо">
        <Tag color={continuedDays > 2 ? 'volcano' : 'gold'} key="tag">
          {`${continuedDays} өдөр`}
        </Tag>
      </Tooltip>
    );
  }
  return null;
};

export const renderRegisteredAt = (task: Task): React.ReactNode => {
  if (task.registeredAt) {
    const timeZone = 'Asia/Ulaanbaatar';
    const ognoo = formatInTimeZone(
      new Date(`${task.registeredAt}.000Z`),
      timeZone,
      'yyyy-MM-dd',
    );
    return (
      <div style={{ textAlign: 'center' }}>
        <div>{ognoo}</div>
        {getTaskContinuedDays(task)}
      </div>
    );
  }
  return null;
};
