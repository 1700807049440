import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { LabeledInput } from '../Common';
import { FlexColumn } from '../Common/Styled';

interface Props {
  token: string;
}

interface PasswordDoc {
  password: string;
  passwordConfirmation: string;
}

// prettier-ignore
const SET_REGISTRATION_PASSWORD = gql`
  mutation setRegistrationPassword($token: String!, $password: String!) {
    setRegistrationPassword(token: $token, password: $password) {
      ok
      message
    }
  }
`;

const PasswordCheckForm: React.VoidFunctionComponent<Props> = ({
  token,
}: Props) => {
  const [messageStatus, setMessageStatus] = useState(0);
  const [actionMutation] = useMutation(SET_REGISTRATION_PASSWORD);

  const [doc, setDoc] = useState<PasswordDoc>({
    password: '',
    passwordConfirmation: '',
  });

  const changeDoc = (name: string, value: string) => {
    setDoc({ ...doc, [name]: value });
  };

  const onSubmit = async (): Promise<void> => {
    if (doc.password === doc.passwordConfirmation) {
      const { data } = await actionMutation({
        variables: { token, password: doc.password },
      });

      if (data.setRegistrationPassword.ok === true) {
        setMessageStatus(1);
      } else {
        setMessageStatus(2);
      }
    } else {
      // ERROR
    }
  };

  return (
    <FlexColumn>
      {messageStatus === 1 && <>Success</>}
      {messageStatus === 2 && <>Error</>}
      {messageStatus === 0 && (
        <>
          <LabeledInput
            label="password"
            type="password"
            defaultValue=""
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              changeDoc('password', e.currentTarget.value)
            }
          />
          <LabeledInput
            label="confirm password"
            type="password"
            value={doc.passwordConfirmation}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              changeDoc('passwordConfirmation', e.currentTarget.value)
            }
          />
          <Button
            type="primary"
            icon={<SaveOutlined />}
            size="large"
            style={{ marginRight: '10px' }}
            defaultValue=""
            onClick={onSubmit}>
            Submit
          </Button>
        </>
      )}
    </FlexColumn>
  );
};

export default PasswordCheckForm;
