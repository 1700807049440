import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Select, Divider, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  aimagId?: string;
  onChange: (text: string) => void;
  [_: string]: unknown;
};

const { Option } = Select;

const DISTRICTS = gql`
  query {
    districts {
      id
      name
      code
      aimag {
        id
      }
    }
  }
`;

// prettier-ignore
const ADD_DISTRICT = gql`
  mutation createDistrict($aimagId: Int!, $name: String!) {
    createDistrict(input: { aimagId: $aimagId, name: $name }) {
      ok
      message
    }
  }
`;

const BranchDistrict = ({
  aimagId,
  onChange,
  ...rest
}: Props): React.ReactElement => {
  const { data, loading, refetch } = useQuery(DISTRICTS);
  const [districts, setDistricts] = useState<District[]>([]);
  const [name, setName] = useState<string>('');
  const [actionMutation] = useMutation(ADD_DISTRICT);

  useEffect(() => {
    let duureg: District[] = [];
    if (data && aimagId) {
      duureg = data.districts.map((item: District, id: number) => ({
        ...item,
        key: `ci-${id}`,
      }));
      if (aimagId) {
        duureg = duureg.filter((v) => +v.aimag?.id === +aimagId);
      }
    }
    setDistricts(duureg.map((d: District) => d));
  }, [data, aimagId]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const addItem = () => {
    actionMutation({ variables: { aimagId, name } })
      .then(() => {
        setName('');
        message.success('Амжилттай гүйцэтгэгдлээ');
        refetch();
      })
      .catch((raw) => {
        const parsedError = JSON.parse(JSON.stringify(raw));
        message.error(parsedError.message);
      });
  };

  return (
    <Select
      loading={loading}
      style={{ width: '100%' }}
      onChange={onChange}
      placeholder=""
      className="required"
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              value={name}
              onChange={onNameChange}
            />
            <Input
              style={{ flex: 'auto' }}
              value={name}
              onChange={onNameChange}
            />
            <button
              type="button"
              tabIndex={0}
              disabled={name.length === 0}
              style={{
                flex: 'none',
                padding: '8px',
                display: 'block',
                cursor: 'pointer',
              }}
              onKeyDown={addItem}
              onClick={addItem}>
              <PlusOutlined /> Нэмэх
            </button>
          </div>
        </div>
      )}
      {...rest}>
      {districts.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

export default BranchDistrict;
