import { PrinterOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from '../Common/Styled';
import {
  addSeparator,
  convertSeconds,
  renderDate,
  renderDateFull,
} from '../Common/utils';
import { renderBranch } from './utils';

const Container = styled.div`
  font-size: 13px;
  padding: 40px 40px 0;
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 13px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  td {
    padding: 5px;
  }
  .underscore {
    border-bottom: 1px dashed black;
    width: 360px;
    display: 'block';
    /* height: 10px; */
  }
`;

const Blank = () => (
  <FlexRow style={{ marginTop: 5, marginLeft: 20 }}>
    <FlexColumn style={{ marginRight: 10 }}>
      <div>гарын үсэг, тамга тэмдэг</div>
      <div>овог нэр</div>
      <div style={{ paddingBottom: -1 }}>албан тушаал</div>
    </FlexColumn>
    <FlexColumn>
      <div className="underscore">&nbsp;</div>
      <div className="underscore">&nbsp;</div>
      <div className="underscore">&nbsp;</div>
    </FlexColumn>
  </FlexRow>
);

const now = new Date();

function ModalPrint({
  selected,
  visible,
  onCancel,
}: ModalProps<Task>): React.ReactElement {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const lastRecord = selected?.taskDetails?.slice(-1)[0];

  const Barimt = (
    <Container>
      <div className="title">Ажил үйлчилгээ хүлээлцсэн акт</div>
      <FlexRow style={{ justifyContent: 'flex-end' }}>
        <div>
          {now.getFullYear()} оны {now.getMonth() + 1} сарын {now.getDate()}
        </div>
      </FlexRow>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Салбар, ТТ, ЭБ-ны нэр</th>
            <th>Гүйцэтгэсэн ажил, үйлчилгээ</th>
            <th>Тайлбар</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selected?.code}</td>
            <td>{selected && renderBranch(selected)}</td>
            <td>{selected?.description}</td>
            <td>{lastRecord?.note}</td>
          </tr>
        </tbody>
      </table>
      <FlexRow style={{ margin: '10px 0' }}>
        <FlexRow style={{ marginRight: 40 }}>
          <FlexColumn style={{ width: 150 }}>
            <strong>Бүртгэсэн огноо</strong>
            <strong>Ажил эхэлсэн</strong>
            <strong>Ажил дууссан</strong>
            <strong>Ажилбар</strong>
          </FlexColumn>
          <FlexColumn>
            <div>
              {selected?.registeredAt
                ? renderDate(selected?.registeredAt.toString())
                : '-'}
            </div>
            <div>{renderDateFull(selected?.minStartedAt) || ''}</div>
            <div>{renderDateFull(selected?.maxFinishedAt) || '-'}</div>
            <div>
              {selected?.workCategories
                ?.map((item) => item.shortName)
                .join(', ')}
            </div>
          </FlexColumn>
        </FlexRow>
        <FlexColumn style={{ width: 150 }}>
          <strong>Зарцуулсан хугацаа</strong>
          <strong>Илүү цагаар</strong>
          <strong>Зардал</strong>
        </FlexColumn>
        <FlexColumn>
          <div>{convertSeconds(selected?.spentTime)}</div>
          <div>{convertSeconds(selected?.overTime)}</div>
          <div>{addSeparator(selected?.totalExpense)} төгрөг</div>
        </FlexColumn>
      </FlexRow>
      <div>Гүйцэтгэсэн ажил үйлчилгээг хүлээн авсан</div>
      <div>ХААН банкны Салбар, ТТ, ЭБ-ийг хариуцсан ажилтан</div>
      <Blank />
      <div>Гүйцэтгэсэн</div>
      <div>Ньюком Проперти ХХК ажилтан</div>
      <Blank />
    </Container>
  );

  return (
    <Modal
      width={800}
      key={`approval-${selected?.id}`}
      visible={visible}
      centered
      footer={false}
      onCancel={onCancel}>
      <Button
        icon={<PrinterOutlined />}
        onClick={handlePrint}
        style={{ float: 'left' }}>
        Хэвлэх
      </Button>
      <div ref={componentRef}>
        {Barimt}
        {Barimt}
      </div>
    </Modal>
  );
}

export default ModalPrint;
