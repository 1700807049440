import React from 'react';
import { Menu as AntMenu, Layout } from 'antd';
import {
  DashboardOutlined,
  ShopOutlined,
  ReadOutlined,
  BankOutlined,
  UnorderedListOutlined as AjilbarIcon,
  CalendarOutlined,
  UserOutlined,
  StarOutlined,
  QuestionOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import LeftArrow from './Icons/LeftArrow.svg';
import RightArrow from './Icons/RightArrow.svg';
import ReactLogo from '../../assets/ncp-logo.png';
import { MenuConstants } from '../Common';
import { FlexRow } from '../Common/Styled';
import { canManageStaff } from '../Common/utils';

type LogoProps = {
  collapsed: boolean;
};

const Keys = [
  {
    route: '/to-confirm-task',
    id: '9',
  },
  {
    route: '/completed-task"',
    id: '3',
  },
  {
    route: '/task',
    id: '2',
  },
  {
    route: '/detail',
    id: '2',
  },
  {
    route: '/branch',
    id: '8',
  },
  {
    route: '/payment',
    id: '5',
  },
  {
    route: '/team',
    id: '6',
  },
  {
    route: '/work-category',
    id: '7',
  },
  {
    route: '/summary',
    id: '4',
  },
  {
    route: '/report',
    id: '10',
  },
  {
    route: '/holiday',
    id: '11',
  },
  {
    route: '/',
    id: '1',
  },
];

const Logo = styled.div`
  display: flex;
  align-items: center;
  color: white;
  padding: 20px 0px;
  padding-left: ${(props: LogoProps) => (props.collapsed ? '10px' : '25px')};
  width: 100%;
  font-size: 24px;
  height: 80px;
  margin-bottom: 40px;
  .cloud {
    border: 1px solid #f4ba40;
    border-radius: 5.25px;
    color: #f4ba40;
    font-size: 9px;
    font-weight: bold;
    padding: 5px;
  }
  div {
    margin-left: 12px;
  }
`;

const Arrow = styled.img`
  margin-top: 13px;
  margin-right: 15px;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const UserGroup = styled.div`
  font-size: 14px;
  line-height: 11px;
  text-align: left;
`;
type User = {
  name: string;
  role: number;
  loginName: string;
};

type TriggerProps = {
  collapsed: boolean;
  user: User;
};

const Trigger = ({ collapsed, user }: TriggerProps) => {
  return (
    <FlexRow
      title={collapsed ? 'Менюг нээх' : 'Менюг хаах'}
      style={{ alignItems: 'center', color: '#DADADA' }}>
      {!collapsed && (
        <FlexRow style={{ alignItems: 'center' }}>
          <div style={{ margin: '10px 10px 10px 15px' }}>
            <UserOutlined style={{ fontSize: 25 }} />
          </div>
          <UserGroup>{user.loginName}</UserGroup>
        </FlexRow>
      )}
      <div>
        <Arrow src={collapsed ? RightArrow : LeftArrow} alt="arrow" />
      </div>
    </FlexRow>
  );
};

type Props = {
  onCollapsed: (collapsed: boolean) => void;
};

const Menu: React.FunctionComponent<Props> = ({ onCollapsed }: Props) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const data = localStorage.getItem('user') || '';
  const user = JSON.parse(data) as User;
  const location = useLocation();
  const key = Keys.find((v) => location.pathname.startsWith(v.route)) || {
    id: '',
  };

  const onMenuCollapse = (value: boolean) => {
    onCollapsed(value);
    setCollapsed(value);
  };

  const menuStyle = {
    fontSize: 22,
  };

  return (
    <Layout.Sider
      collapsible
      collapsedWidth={MenuConstants.collapsedWidth}
      width={MenuConstants.width}
      onCollapse={onMenuCollapse}
      trigger={<Trigger collapsed={collapsed} user={user} />}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
      }}>
      <Logo collapsed={collapsed}>
        <img src={ReactLogo} alt="NCP" width={collapsed ? 45 : 100} />
      </Logo>
      <AntMenu theme="dark" mode="inline" defaultSelectedKeys={[key.id]}>
        <AntMenu.Item key="1" icon={<DashboardOutlined style={menuStyle} />}>
          <Link to="/">Хянах самбар</Link>
        </AntMenu.Item>
        <AntMenu.Item key="2" icon={<ShopOutlined style={menuStyle} />}>
          <Link to="/task">Захиалга</Link>
        </AntMenu.Item>
        <AntMenu.Item key="9" icon={<QuestionOutlined style={menuStyle} />}>
          <Link to="/to-confirm-task">Хийсэн ажлууд</Link>
        </AntMenu.Item>
        <AntMenu.Item key="3" icon={<StarOutlined style={menuStyle} />}>
          <Link to="/completed-task">Хүлээлцсэн ажлууд</Link>
        </AntMenu.Item>
        <AntMenu.Item key="4" icon={<ReadOutlined style={menuStyle} />}>
          <Link to="/summary">Mэдээ</Link>
        </AntMenu.Item>
        <AntMenu.Item key="10" icon={<FileTextOutlined style={menuStyle} />}>
          <Link to="/report">Тайлан</Link>
        </AntMenu.Item>
        {/* {canManageTeams() && (
          <AntMenu.Item key="6" icon={<TeamOutlined style={menuStyle} />}>
            <Link to="/team">Баг, ажилчид</Link>
          </AntMenu.Item>
        )} */}
        <AntMenu.Item key="7" icon={<AjilbarIcon style={menuStyle} />}>
          <Link to="/work-category">Ажилбар</Link>
        </AntMenu.Item>
        <AntMenu.Item key="8" icon={<BankOutlined style={menuStyle} />}>
          <Link to="/branch">Салбар</Link>
        </AntMenu.Item>
        <AntMenu.Item key="11" icon={<CalendarOutlined style={menuStyle} />}>
          <Link to="/holiday">Амралтын өдрүүд</Link>
        </AntMenu.Item>
        {canManageStaff() && (
          <AntMenu.Item key="12" icon={<CalendarOutlined style={menuStyle} />}>
            <Link to="/staff">Менежерүүд</Link>
          </AntMenu.Item>
        )}
      </AntMenu>
    </Layout.Sider>
  );
};

export default Menu;
