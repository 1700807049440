import React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../Common/Styled';

const Container = styled.div`
  td {
    border: 0.3px solid black;
    padding: 4px 6px;
    text-align: center;
    line-height: 22px;
  }

  .zuraas {
    border-top: 2px solid black;
  }

  .title {
    border: 1px dotted black;
  }

  .first {
    min-width: 40px;
    max-width: 40px;
    text-align: center;
  }

  .data-column {
    min-width: 135px;
  }
`;

const Value = styled.div`
  text-align: right;
  margin-right: 5px;
  flex: 4;
`;

const Unit = styled.div`
  text-align: left;
  font-style: italic;
  color: grey;
  font-size: 13px;
  flex: 5;
`;

const renderRow = (value?: number, unit?: string, precision?: number) => (
  <FlexRow style={{ alignItems: 'center' }}>
    <Value>{value?.toFixed(precision === undefined ? 1 : precision)}</Value>
    <Unit>{unit}</Unit>
  </FlexRow>
);

type Props = {
  title?: string;
  summary?: SummaryType;
  names?: string[];
};

function Monthly({ title, summary, names = [''] }: Props): React.ReactElement {
  const byType = summary?.byType || [];
  return (
    <Container>
      <table>
        <tbody>
          <tr>
            <td className="data-column title">
              <strong>{title}</strong>
            </td>
          </tr>
          <tr className="zuraas">
            <td className="data-column">
              {renderRow(summary?.workDays, 'хоног')}
            </td>
          </tr>
          <tr>
            <td className="data-column">
              {renderRow(summary?.all.branchCount, 'захиалга')}
            </td>
          </tr>
          <tr>
            <td className="data-column">
              {renderRow(summary?.all.averagePerDay, 'захиалга')}
            </td>
          </tr>
          <tr className="zuraas">
            <td className="data-column">
              {renderRow(summary?.completed.totalCount, 'гүйцэтгэл', 0)}
            </td>
          </tr>
          <tr>
            <td className="data-column">
              {renderRow(summary?.completed.averagePerDay, 'гүйцэтгэл')}
            </td>
          </tr>

          {names.map((v, i) => {
            const found = byType.find((b) => b.name === v) || { monthly: 0 };
            return (
              <tr key={i}>
                <td className="data-column">
                  {renderRow(found.monthly, 'ажилбар')}
                </td>
              </tr>
            );
          })}

          {names.map((v, i) => {
            const found = byType.find((b) => b.name === v) || { daily: 0 };
            return (
              <tr key={i} className={i === 0 ? 'zuraas' : ''}>
                <td className="data-column">
                  {renderRow(found.daily, 'ажилбар')}
                </td>
              </tr>
            );
          })}

          <tr className="zuraas">
            <td className="data-column">
              {renderRow(summary?.incompleteCount, 'захиалга', 0)}
            </td>
          </tr>
          <tr>
            <td className="data-column">
              {renderRow(summary?.uncontractedCount, 'захиалга', 0)}
            </td>
          </tr>

          <tr>
            <td className="data-column">
              {renderRow(summary?.averageDaysCompleted, 'өдөр')}
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
}

export default Monthly;
