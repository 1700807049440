import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  InputNumber,
  Table,
  Tooltip,
  Button,
  Input,
  Popconfirm,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Label } from '../Common';
import { FlexRow } from '../Common/Styled';
import { addSeparator } from '../Common/utils';

const GET_EXPENSES = gql`
  query ($id: Int!) {
    getTask(id: $id) {
      id
      expenseNote
      expenses {
        id
        itemName
        unitPrice
        qty
        taskId
      }
    }
  }
`;

interface MyProps {
  task?: Task;
  onChange: (expense: Expense[]) => void;
}

function ExpenseControl({ task, onChange }: MyProps): React.ReactElement {
  const [total, setTotal] = useState<number>(0);
  const [doc, setDoc] = useState<Expense>({ qty: 1 });
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const { data, refetch } = useQuery(GET_EXPENSES, {
    variables: { id: task?.id },
  });

  const onExpensesChange = (updated: Expense[]) => {
    onChange(
      updated.map(({ itemName, unitPrice, qty }: Expense) => ({
        itemName,
        unitPrice,
        qty,
      })),
    );
  };

  const onAdd = () => {
    const { itemName, unitPrice, qty } = doc;
    if (task && itemName?.trim() && unitPrice && qty) {
      doc.key = Math.floor(Math.random() * 1000);
      const updated = [doc].concat(expenses);
      setExpenses(updated);
      onExpensesChange(updated);
      setDoc({ qty: 1 });
      refetch();
    } else {
      message.destroy();
      message.warn('Зардлын мэдээллийг бүрэн оруулна уу');
    }
  };

  const onClickDelete = (item: Expense) => {
    if (item) {
      const updated = expenses.filter((v) => v.key !== item.key);
      setExpenses(updated);
      onExpensesChange(updated);
    }
  };

  React.useEffect(() => {
    if (task) {
      refetch();
    }
  }, [task]);

  React.useEffect(() => {
    if (data) {
      setExpenses(
        data.getTask.expenses.map((v: Expense, i: number) => ({
          ...v,
          key: i,
        })),
      );
    }
  }, [data]);

  React.useEffect(() => {
    if (expenses) {
      setTotal(
        expenses.reduce((acc, curr) => {
          if (curr?.unitPrice && curr?.qty) {
            return acc + curr.unitPrice * curr.qty;
          }
          return acc;
        }, 0),
      );
    }
  }, [expenses]);

  const renderDeletion = (item: Expense) => (
    <Tooltip title="Устгах">
      <Popconfirm
        placement="bottomRight"
        title="Зардлыг устгахдаа итгэлтэй байна уу?"
        onConfirm={() => onClickDelete(item)}
        okText="Тийм"
        cancelText="Үгүй">
        <Button icon={<DeleteOutlined />} type="link" />
      </Popconfirm>
    </Tooltip>
  );

  const calcTotal = (item: Expense): number => {
    if (item?.qty && item?.unitPrice) {
      return item.qty * item.unitPrice;
    }
    return 0;
  };

  const columns: ColumnsType<Expense> = [
    {
      title: 'Зардлын нэр',
      dataIndex: 'itemName',
    },
    {
      title: 'Нэгж',
      dataIndex: 'qty',
      sorter: (a: Expense, b: Expense) => (a.qty || 0) - (b.qty || 0),
    },
    {
      title: 'Нэгжийн үнэ',
      render: (item: Expense) => addSeparator(item.unitPrice),
      align: 'right',
      sorter: (a: Expense, b: Expense) =>
        (a.unitPrice || 0) - (b.unitPrice || 0),
    },
    {
      title: 'Дүн',
      align: 'right',
      render: (a) => addSeparator(calcTotal(a)),
      sorter: (a: Expense, b: Expense) => calcTotal(a) - calcTotal(b),
    },
    {
      title: '',
      width: 30,
      render: renderDeletion,
    },
  ];

  return (
    <div>
      <FlexRow style={{ alignItems: 'flex-end', marginBottom: 15 }}>
        <div style={{ flex: 9 }}>
          <Label>Зардлын нэр</Label>
          <Input
            value={doc?.itemName}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setDoc({ ...doc, itemName: e.currentTarget.value });
              message.destroy();
            }}
          />
        </div>
        <div style={{ flex: 2, margin: '0 15px' }}>
          <Label>Нэгж</Label>
          <Tooltip title="Тоо ширхэгээр">
            <InputNumber
              style={{ width: '100%' }}
              defaultValue={1}
              value={doc?.qty}
              min={0}
              onChange={(qty) => {
                setDoc({ ...doc, qty });
                message.destroy();
              }}
            />
          </Tooltip>
        </div>
        <div style={{ flex: 3, marginRight: 15 }}>
          <Label>Нэгжийн үнэ</Label>
          <InputNumber
            style={{ width: '100%' }}
            defaultValue={0}
            value={doc?.unitPrice}
            onChange={(unitPrice) => {
              setDoc({ ...doc, unitPrice });
              message.destroy();
            }}
          />
        </div>
        <Tooltip title="Зардал оруулах">
          <Button icon={<PlusOutlined />} onClick={onAdd}>
            Нэмэх
          </Button>
        </Tooltip>
      </FlexRow>
      <div>
        <Table<Expense>
          columns={columns}
          dataSource={expenses}
          size="small"
          footer={() => (
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <strong>Нийт дүн</strong>
              <div>
                <strong>{`${addSeparator(total)} `}</strong>төгрөг
              </div>
            </FlexRow>
          )}
          pagination={{ pageSize: 5 }}
        />
      </div>
    </div>
  );
}

export default ExpenseControl;
