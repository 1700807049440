import React, { useState, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Alert } from 'antd';
import { Paper } from '../Common';
import { FlexRow } from '../Common/Styled';
import PasswordCheckForm from './PasswordCheckForm';

const CHECK_TOKEN = gql`
  mutation checkToken($token: String!) {
    checkToken(token: $token) {
      ok
      message
    }
  }
`;

interface ConfirmationParams {
  token: string;
}

const RegistrationConfirmation: React.VoidFunctionComponent = () => {
  const [actionMutation, { loading, error }] = useMutation(CHECK_TOKEN);
  const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
  const params = useParams<ConfirmationParams>();
  const { token } = params;

  useEffect(() => {
    const runMutation = async (): Promise<void> => {
      const { data } = await actionMutation({ variables: { token } });

      if (data.checkToken.ok) {
        setShowPasswordFields(true);
      }
    };

    runMutation();
  }, []);

  if (loading) return <Alert message="Submitting..." type="info" />;
  if (error)
    return (
      <Alert message={`Submission error! ${error.message}`} type="error" />
    );

  return (
    <Paper style={{ maxWidth: 840 }}>
      <FlexRow style={{ justifyContent: 'space-around' }}>
        {showPasswordFields && <PasswordCheckForm token={token} />}
      </FlexRow>
    </Paper>
  );
};

export default RegistrationConfirmation;
