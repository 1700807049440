import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Button, Modal, Tooltip } from 'antd';
import { extractLocation } from '../Common/utils';
import { FlexColumn, FlexRow } from '../Common/Styled';

type BranchGroup = {
  id: string;
  name: string;
  lat: number;
  lng: number;
  count: number;
};

const Tool = ({ id, name, count, lat, lng }: BranchGroup) => (
  <FlexRow key={id}>
    <FlexColumn style={{ marginRight: 20 }}>
      <div>Байршил</div>
      <div>Координат</div>
      <div>Захиалгын тоо</div>
    </FlexColumn>
    <FlexColumn>
      <div>{name || '-'}</div>
      <div>{`${lat.toFixed(2)}° ${lng.toFixed(2)}°`}</div>
      <div>{count || 0}</div>
    </FlexColumn>
  </FlexRow>
);

const Marker = (props: BranchGroup) => (
  <div style={{ position: 'relative' }}>
    <div
      key={props.id}
      style={{
        left: -10,
        top: -15,
        fontSize: 17,
        color: '#030e22',
        position: 'absolute',
      }}>
      <Tooltip title={<Tool {...props} />}>
        <Button
          shape="circle"
          type="primary"
          size="small"
          style={{ padding: 0 }}>
          {props.count}
        </Button>
      </Tooltip>
    </div>
  </div>
);

const getMinMax = (numbers: number[]) => {
  const max = Math.max(...numbers);
  const min = Math.min(...numbers);
  return [max, min];
};

type MapModalProps = {
  visible: boolean;
  tasks: Task[];
  onCancel: () => void;
};

function ModalMap({
  visible,
  tasks = [],
  onCancel,
}: MapModalProps): React.ReactElement {
  const [lat, setLat] = React.useState<number>(47.87);
  const [lng, setLng] = React.useState<number>(106.89);
  const [localTasks, setLocalTasks] = React.useState<BranchGroup[]>([]);
  const defaultMapProps = {
    center: { lat, lng },
    zoom: 9,
  };

  React.useEffect(() => {
    let grouped: BranchGroup[] = [];
    if (tasks && tasks.length > 0) {
      grouped = tasks.reduce((acc: BranchGroup[], curr: Task) => {
        const found = acc.find((v) => v.id === curr.branch.id);
        if (found) {
          found.count += 1;
        } else if (curr.branch?.gpsCoordinat) {
          const [lt, lg] = extractLocation(curr.branch?.gpsCoordinat);
          if (lt && lg) {
            acc.push({
              id: curr.branch.id,
              name: curr.branch.name,
              lat: lt || 47.87,
              lng: lg || 106.89,
              count: 1,
            });
          }
        }
        return acc;
      }, []);
      if (grouped.length > 0) {
        const [maxLat, minLat] = getMinMax(grouped.map((v) => v.lat));
        const [maxLng, minLng] = getMinMax(grouped.map((v) => v.lng));
        if (!Number.isNaN(maxLat) && !Number.isNaN(minLat))
          setLat((maxLat + minLat) / 2);
        if (!Number.isNaN(maxLng) && !Number.isNaN(minLng))
          setLng((maxLng + minLng) / 2);
      }
    }
    setLocalTasks(grouped);
  }, [tasks]);

  return (
    <Modal
      key="modal-map"
      title="Захиалгуудын тархац"
      visible={visible}
      centered
      width={1100}
      footer={null}
      onCancel={onCancel}>
      <div style={{ height: '80vh' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyCC5vhwoxOo3WE2GtipapOG1mWad1Y93jA',
            language: 'mn',
          }}
          center={defaultMapProps.center}
          zoom={defaultMapProps.zoom}>
          {localTasks.map((branch) => (
            <Marker key={branch.id} {...branch} />
          ))}
        </GoogleMapReact>
      </div>
    </Modal>
  );
}

export default ModalMap;
