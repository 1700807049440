import React from 'react';
import { Input, Button, Tooltip } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import { FlexRow } from './Styled';
import { Paper } from './Paper';

type Props = {
  value: string;
  count?: number;
  tooltip?: string;
  placeholder?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onClickAdd?: () => void;
  onClickAddEmergency?: () => void;
  emergencyTooltip?: string;
  emergencyPlaceholder?: string;
  children?: React.ReactNode;
};

const ControlBar: React.FunctionComponent<Props> = ({
  value,
  count = 0,
  tooltip,
  placeholder,
  onChange,
  onClickAdd,
  onClickAddEmergency,
  emergencyTooltip,
  emergencyPlaceholder,
  children,
}: Props) => {
  return (
    <Paper style={{ padding: 15 }}>
      <FlexRow style={{ alignItems: 'center' }}>
        <FlexRow
          style={{
            minWidth: 100,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <strong>Нийт:</strong>
          <div style={{ flex: 1, textAlign: 'center', padding: '0 20px' }}>
            {count}
          </div>
        </FlexRow>
        <div style={{ flex: 1 }}>
          <Tooltip title="Захиалгаас хайх">
            <Input
              placeholder={placeholder}
              size="large"
              value={value}
              onChange={onChange}
            />
          </Tooltip>
        </div>
        {children}
        {onClickAdd && (
          <Tooltip title={tooltip}>
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={onClickAdd}>
              {tooltip}
            </Button>
          </Tooltip>
        )}
        {onClickAddEmergency && (
          <Tooltip title={emergencyTooltip}>
            <Button
              style={{ marginLeft: 10, background: 'red', borderColor: 'red' }}
              type="primary"
              color="red"
              icon={<PlusSquareOutlined />}
              onClick={onClickAddEmergency}>
              {emergencyPlaceholder}
            </Button>
          </Tooltip>
        )}
      </FlexRow>
    </Paper>
  );
};

export default ControlBar;
