import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { SaveOutlined, UserOutlined } from '@ant-design/icons';
import { Label, Paper } from '../Common';
import { FlexColumn, FlexRow } from '../Common/Styled';

const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $oldPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      oldPassword: $oldPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      ok
      message
    }
  }
`;

interface UserInputType {
  oldPassword: string;
  password: string;
  repeat: string;
}
export interface FieldData {
  name: string | number | (string | number)[];
  value?: string | number;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const Settings: React.VoidFunctionComponent = () => {
  const data = localStorage.getItem('user') || '';
  const user = JSON.parse(data) as User;
  const [doc, setDoc] = React.useState<UserInputType>({
    oldPassword: '',
    password: '',
    repeat: '',
  });
  const [actionMutation] = useMutation(UPDATE_PASSWORD);

  const setDocValue = (name: string, value: string) => {
    setDoc({ ...doc, [name]: value });
  };

  const onSubmit = () => {
    const variables = {
      oldPassword: doc.oldPassword,
      password: doc.password,
      passwordConfirmation: doc.repeat,
    };
    actionMutation({ variables })
      .then(() => {
        message.success('Нууц үг амжилттай солигдлоо');
        setDoc({
          oldPassword: '',
          password: '',
          repeat: '',
        });
      })
      .catch((raw) => {
        const parsedError = JSON.parse(JSON.stringify(raw));
        message.error(parsedError.message);
      });
  };

  return (
    <Paper style={{ maxWidth: 510 }}>
      <FlexRow style={{ alignItems: 'center', marginBottom: 20, width: 300 }}>
        <UserOutlined style={{ fontSize: 20 }} />
        <h2 style={{ margin: '0 0 0 10px' }}>{user.loginName}</h2>
      </FlexRow>
      <Form
        fields={[
          { name: 'old', value: doc.oldPassword, errors: [] },
          { name: 'password', value: doc.password, errors: [] },
          { name: 'repeat', value: doc.repeat, errors: [] },
        ]}
        onFinish={onSubmit}
        autoComplete="off">
        <FlexColumn>
          <Form.Item
            name="old"
            rules={[
              {
                required: true,
                message: 'Ашиглаж байгаа нууц үгээ оруулна уу',
              },
            ]}>
            <Label>Нууц үг</Label>
            <Input.Password
              className="required"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDocValue('oldPassword', e.currentTarget.value)
              }
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Шинэ нууц үгээ оруулна уу' }]}>
            <Label>Шинэ нууц үг</Label>
            <Input.Password
              className="required"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDocValue('password', e.currentTarget.value)
              }
            />
          </Form.Item>

          <Form.Item
            name="repeat"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Шинэ нууц үгээ оруулна уу',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Баталгаажуулах нууц үг таарахгүй байна!'),
                  );
                },
              }),
            ]}>
            <Label>Шинэ нууц үгээ давтах</Label>
            <Input.Password
              className="required"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDocValue('repeat', e.currentTarget.value)
              }
            />
          </Form.Item>

          <FlexRow style={{ justifyContent: 'flex-end', marginTop: 20 }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                size="large">
                Нууц үг өөрчлөх
              </Button>
            </Form.Item>
          </FlexRow>
        </FlexColumn>
      </Form>
    </Paper>
  );
};

export default Settings;
