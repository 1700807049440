import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Select } from 'antd';

const { Option } = Select;

interface Props {
  onChange: (value: string[]) => void;
  [_: string]: unknown;
}

const WORK_CATEGORIES = gql`
  query {
    workCategories {
      id
      name
      shortName
    }
  }
`;

const SelectWorkCategory: React.FunctionComponent<Props> = ({
  onChange,
  ...rest
}: Props) => {
  const { data, loading } = useQuery(WORK_CATEGORIES);
  const [categories, setCategories] = React.useState<WorkCategory[]>([]);

  React.useEffect(() => {
    if (data) {
      const workCategories = data.workCategories.map(
        (item: WorkCategory, index: number) => ({
          ...item,
          key: `ci-${index}`,
        }),
      );
      setCategories(workCategories);
    }
  }, [data]);

  return (
    <Select<string[]>
      mode="multiple"
      style={{ width: '100%' }}
      onChange={onChange}
      loading={loading}
      {...rest}>
      {categories.map((branch: WorkCategory, i: number) => (
        <Option key={`option-${i}`} value={branch.id}>
          {branch.shortName}
        </Option>
      ))}
    </Select>
  );
};

export default SelectWorkCategory;
