import React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../Common/Styled';

const Container = styled.div`
  td {
    border: 0.3px solid black;
    padding: 4px 6px;
    line-height: 22px;
  }

  .first {
    min-width: 40px;
    max-width: 40px;
    text-align: center;
  }

  .too {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    font-style: italic;
  }

  .title {
    text-align: center;
    border: 1px dotted black;
    margin-bottom: 10px;
    padding: 20px 6px;
  }

  .zuraas {
    border-top: 2px solid black;
  }

  .zahialga {
    min-width: 215px;
  }

  .niit {
    min-width: 230px;
  }

  .small-note {
    margin-left: 4px;
    font-size: 13px;
    color: #9c4545;
  }
`;

type Props = {
  byType?: Turul[];
};

const MainTable: React.FunctionComponent<Props> = ({
  byType = [{ name: '-', daily: 0, monthly: 0 }],
}: Props) => {
  return (
    <Container>
      <table style={{ width: '1px', marginRight: 24 }}>
        <tbody>
          <tr>
            <td className="first title">#</td>
            <td colSpan={3} className="title">
              <strong>НЭР</strong>
            </td>
          </tr>
          <tr className="zuraas">
            <td className="first">1</td>
            <td colSpan={3}>Ажилласан</td>
          </tr>
          <tr>
            <td rowSpan={2} className="first">
              2
            </td>
            <td rowSpan={2}>Захиалга</td>
            <td colSpan={2}>Нийт</td>
          </tr>
          <tr>
            <td colSpan={2}>Өдрийн дундаж</td>
          </tr>

          <tr className="zuraas">
            <td rowSpan={2} className="first">
              3
            </td>
            <td rowSpan={2} className="zahialga">
              Гүйцэтгэсэн ажилбар
            </td>
            <td colSpan={2} className="niit">
              Нийт
            </td>
          </tr>
          <tr>
            <td colSpan={2}>Өдрийн дундаж</td>
          </tr>

          <tr>
            <td rowSpan={byType.length} className="first">
              4
            </td>
            <td rowSpan={byType.length}>
              <FlexRow>
                <div>Төрөл</div>
                <div className="small-note">(сарын гүйцэтгэл)</div>
              </FlexRow>
            </td>
            <td>{byType[0].name}</td>
            <td className="too">{byType[0].monthly?.toFixed(0)}%</td>
          </tr>
          {byType.slice(1).map((v, i) => (
            <tr key={i}>
              <td>{v.name}</td>
              <td className="too">{v.monthly?.toFixed(0)}%</td>
            </tr>
          ))}

          <tr className="zuraas">
            <td rowSpan={byType.length} className="first">
              5
            </td>
            <td rowSpan={byType.length}>
              <FlexRow>
                <div>Төрөл</div>
                <div className="small-note">(өдрийн дундаж)</div>
              </FlexRow>
            </td>
            <td>{byType[0].name}</td>
            <td rowSpan={byType.length} className="too">
              {byType
                .reduce((acc, curr) => {
                  return acc + curr.daily;
                }, 0)
                .toFixed(2)}
            </td>
          </tr>
          {byType.slice(1).map((v, i) => (
            <tr key={i}>
              <td>{v.name}</td>
            </tr>
          ))}

          <tr className="zuraas">
            <td rowSpan={4} className="first">
              6
            </td>
            <td colSpan={3}>Дуусаагүй захиалга</td>
          </tr>
          <tr>
            <td colSpan={3}>Гэрээнд ороогүй захиалга</td>
          </tr>
          <tr>
            <td colSpan={3}>
              Захиалга өгсөнөөс хойш гүйцэтгэсэн дундаж хугацаа
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

export default MainTable;
