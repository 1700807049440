import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Button, message, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons';
import { FlexRow } from '../Common/Styled';
import { Paper, Table } from '../Common';
import ControlBar from '../Common/ControlBar';
import BranchModal, { BRANCH_TYPE } from './BranchModal';
import { canAddOrEditBranch } from '../Common/utils';
import SelectAimag from './SelectAimag';

const BRANCHES = gql`
  query {
    branches {
      id
      name
      code
      type
      description
      managerName
      phone
      aimag {
        id
        name
        code
      }
      district {
        id
        name
        code
      }
      gpsCoordinat
    }
  }
`;

const Branch: React.VoidFunctionComponent = () => {
  const [selected, setSelected] = React.useState<Branch>();
  const [searchKey, setSearchKey] = React.useState<string>('');
  const [aimagId, setAimagId] = React.useState<string>('');
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const { error, loading, data, refetch } = useQuery(BRANCHES);

  const getFromOrigin = (): Branch[] => {
    if (data) {
      return data.branches.map((item: Branch, index: number) => ({
        ...item,
        key: `ci-${index}`,
      }));
    }
    return [];
  };

  const filterBranches = (values: Branch[]): Branch[] => {
    let tempValues = values;
    if (aimagId) {
      tempValues = tempValues.filter((b) => b.aimag.id === aimagId);
    }
    if (searchKey) {
      tempValues = tempValues.filter(
        (v: Branch) =>
          v.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
          v.code?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
          v.aimag.name
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          v.district.name
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          v.managerName
            ?.toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          v.phone
            ?.toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          v.description
            ?.toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()),
      );
    }
    return tempValues;
  };

  React.useEffect(() => {
    if (loading) {
      message.info('Уншиж байна...');
    } else if (error) {
      message.error('Өгөгдөл татахад алдаа гарлаа');
    } else if (data) {
      message.destroy();
      setBranches(filterBranches(getFromOrigin()));
    }
  }, [loading, error, data]);

  React.useEffect(() => {
    setBranches(filterBranches(getFromOrigin()));
  }, [searchKey, aimagId]);

  const showModal = () => {
    setSelected(undefined);
    setIsModalVisible(true);
  };

  const onOk = () => {
    setIsModalVisible(false);
    refetch();
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onEdit = (branch: Branch) => {
    showModal();
    setSelected(branch);
  };

  const renderActions = (item: Branch) => (
    <FlexRow style={{ justifyContent: 'center' }}>
      {canAddOrEditBranch() && (
        <Tooltip title="Засварлах">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => onEdit(item)}
          />
        </Tooltip>
      )}
    </FlexRow>
  );

  const columns: ColumnsType<Branch> = [
    {
      title: 'Код',
      dataIndex: 'code',
    },
    {
      title: 'Байршил',
      render: function BranchName(branch: Branch) {
        return (
          <div>
            <div>{branch?.name}</div>
            <div
              style={{
                fontSize: 13,
                color: 'grey',
              }}>{`${branch?.aimag?.name}, ${branch?.district?.name}`}</div>
          </div>
        );
      },
    },
    {
      title: 'Төрөл',
      render: function renderType(value: Branch) {
        const found = BRANCH_TYPE.find((v) => +v.id === +value.type);
        return found ? found.name : '';
      },
      width: 130,
    },
    {
      title: 'Менежер',
      dataIndex: 'managerName',
    },
    {
      title: 'Утас',
      dataIndex: 'phone',
    },
    {
      title: 'Тайлбар',
      dataIndex: 'description',
    },
  ];

  return (
    <div>
      <ControlBar
        onClickAdd={canAddOrEditBranch() ? showModal : undefined}
        value={searchKey}
        tooltip="Салбар нэмэх"
        placeholder="Салбар хайх"
        count={branches.length}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          setSearchKey(e.currentTarget.value)
        }>
        <div style={{ width: 180, marginLeft: 10 }}>
          <Tooltip title="Харьяаллаар шүүх">
            <SelectAimag
              size="large"
              placeholder="Харъяалал сонгох"
              allowClear
              onChange={(a) => setAimagId(a)}
            />
          </Tooltip>
        </div>
      </ControlBar>
      <Paper>
        <Table<Branch>
          columns={columns}
          data={branches}
          action={renderActions}
        />
        <BranchModal
          onCancel={onCancel}
          selected={selected}
          onOk={onOk}
          visible={isModalVisible}
        />
      </Paper>
    </div>
  );
};

export default Branch;
