import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Image, Table as AntTable, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  HistoryOutlined,
  SettingOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { Paper, Table, renderDateFull } from '../Common';
import ModalCreate from './ModalCreate';
import { FlexRow } from '../Common/Styled';
import ModalTimeState from './ModalTimeState';
import ModalExpenses from './ModalExpenses';
import TaskInfo from './TaskInfo';
import {
  addSeparator,
  canEditOtherInfo,
  canEditTimeStatus,
  sortDates,
} from '../Common/utils';
import { ACTIONS, renderNote, renderStatus } from './utils';

const TASK_HISTORY = gql`
  query ($id: Int!) {
    getTask(id: $id) {
      id
      code
      description
      createdAt
      registeredAt
      branch {
        id
        name
        aimag {
          name
        }
        district {
          name
        }
      }
      totalExpense
      expenseNote
      status
      isEmergency
      workCategories {
        id
        name
        shortName
      }
      user {
        loginName
      }
      taskLog {
        status
        description
        updatedAt
        expenseNote
        totalExpense
        branch {
          id
          name
          aimag {
            name
          }
          district {
            name
          }
        }
        user {
          loginName
        }
        images {
          id
          note
          filename
          url
        }
        action
        approveNote
        expenses {
          id
          itemName
          unitPrice
          qty
        }
        taskDetail {
          startedAt
          finishedAt
          note
          numofPr
        }
      }
      images {
        id
        note
        filename
        url
      }
      approved
      spentTime
      overTime
      taskDetails {
        startedAt
        finishedAt
        note
        numofPr
      }
    }
  }
`;

const calcTotal = (item: Expense): number => {
  if (item?.qty && item?.unitPrice) {
    return item.qty * item.unitPrice;
  }
  return 0;
};

const expenseCols: ColumnsType<Expense> = [
  {
    title: 'Зардлын нэр',
    dataIndex: 'itemName',
  },
  {
    title: 'Нэгж',
    dataIndex: 'qty',
    sorter: (a: Expense, b: Expense) => (a.qty || 0) - (b.qty || 0),
  },
  {
    title: 'Нэгжийн үнэ',
    render: (item: Expense) => addSeparator(item.unitPrice),
    align: 'right',
    sorter: (a: Expense, b: Expense) => (a.unitPrice || 0) - (b.unitPrice || 0),
  },
  {
    title: 'Дүн',
    align: 'right',
    render: (a) => addSeparator(calcTotal(a)),
    sorter: (a: Expense, b: Expense) => calcTotal(a) - calcTotal(b),
  },
];

type TaskParams = {
  id?: string;
};

const TaskHistory: React.VoidFunctionComponent = () => {
  const [selected, setSelected] = React.useState<Task>();
  const [showOthers, setShowOthers] = React.useState<boolean>(false);
  const [showTimeState, setShowTimeState] = React.useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [taskDetails, setTaskDetails] = React.useState<TaskHistory[]>([]);
  const { id } = useParams<TaskParams>();
  const history = useHistory();
  const { data, loading, refetch } = useQuery(TASK_HISTORY, {
    variables: { id },
  });

  React.useEffect(() => {
    if (loading) {
      message.loading('Уншиж байна...');
    } else if (data) {
      message.destroy();
      const task = data.getTask as Task;
      setSelected(task);
      setTaskDetails(
        (task.taskLog || []).map((item: TaskHistory, key: number) => ({
          ...item,
          key,
        })),
      );
    }
  }, [data, loading]);

  const columns: ColumnsType<TaskHistory> = [
    {
      title: 'Огноо',
      dataIndex: 'updatedAt',
      render: renderDateFull,
      sorter: (a: TaskHistory, b: TaskHistory) =>
        sortDates(a.updatedAt, b.updatedAt),
      width: 135,
    },
    {
      title: 'Өөрчилсөн',
      render: function User(task: TaskHistory) {
        return task?.user.loginName;
      },
    },
    {
      title: 'Тэмдэглэл',
      render: renderNote,
    },
    {
      title: 'Зардал',
      render: function User(task: TaskHistory) {
        return (
          <div>
            {task.action === ACTIONS[2]
              ? `${addSeparator(task.totalExpense)} төгрөг`
              : ''}
          </div>
        );
      },
    },
    {
      title: 'Зураг',
      render: function GetType(task: TaskHistory) {
        return (
          <FlexRow style={{ justifyContent: 'space-around' }}>
            {task.images?.map((i) => (
              <Tooltip key={i.id} title={i.note} placement="rightTop">
                <Image
                  style={{
                    border: '1px solid grey',
                    borderRadius: 5,
                  }}
                  src={i.url}
                  width={30}
                  height={30}
                  preview={{
                    mask: undefined,
                  }}
                />
              </Tooltip>
            ))}
          </FlexRow>
        );
      },
    },
    {
      title: 'Төлөв',
      render: renderStatus,
    },
    {
      key: 'startedAt',
      title: 'Эхэлсэн',
      dataIndex: ['taskDetail', 'startedAt'],
      width: 135,
      sorter: (a: TaskHistory, b: TaskHistory) =>
        sortDates(a.startedAt, b.startedAt),
      render: renderDateFull,
    },
    {
      key: 'inishedAt',
      title: 'Дууссан',
      dataIndex: ['taskDetail', 'finishedAt'],
      width: 135,
      sorter: (a: TaskHistory, b: TaskHistory) =>
        sortDates(a.finishedAt, b.finishedAt),
      render: renderDateFull,
    },
  ];

  const renderNestedTable = (task: TaskHistory) => (
    <AntTable
      size="small"
      className="nested"
      columns={expenseCols}
      dataSource={task.expenses?.map((v) => ({ ...v, key: v.id }))}
      pagination={false}
    />
  );

  return (
    <div>
      <TaskInfo selected={selected}>
        <FlexRow style={{ marginTop: 10 }}>
          <Button icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
            Буцах
          </Button>
          {!selected?.approved && canEditTimeStatus() && (
            <Button
              style={{ margin: '0 20px' }}
              icon={<HistoryOutlined />}
              onClick={() => setShowTimeState(true)}>
              Цаг төлөв өөрчлөх
            </Button>
          )}
          {!selected?.approved && canEditOtherInfo() && (
            <Button
              icon={<SettingOutlined />}
              onClick={() => setShowOthers(true)}>
              Зардлын мэдээлэл засах
            </Button>
          )}
        </FlexRow>
      </TaskInfo>
      <Paper>
        <Table<TaskHistory>
          columns={columns}
          data={taskDetails}
          expandableConfig={{
            rowExpandable: (r) => (r.expenses || []).length > 0,
            expandedRowRender: renderNestedTable,
            expandRowByClick: true,
            indentSize: 2140,
          }}
        />
        <ModalCreate
          onCancel={() => setIsModalVisible(false)}
          selected={selected}
          onOk={() => {
            setIsModalVisible(false);
            refetch();
          }}
          visible={isModalVisible}
        />
        <ModalTimeState
          onCancel={() => setShowTimeState(false)}
          selected={selected}
          onOk={() => {
            setShowTimeState(false);
            refetch();
          }}
          visible={showTimeState}
        />
        <ModalExpenses
          onCancel={() => setShowOthers(false)}
          selected={selected}
          onOk={() => {
            setShowOthers(false);
            refetch();
          }}
          visible={showOthers}
        />
      </Paper>
    </div>
  );
};

export default TaskHistory;
