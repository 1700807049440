import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import 'antd/dist/antd.css';
import { Form, Input, Button, Typography, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Paper } from '../Common/Paper';
import { FlexColumn } from '../Common/Styled';
import Logo from '../../assets/ncp-logo.png';
import UserSelect from './UseSelect';

const LOGIN_USER = gql`
  mutation loginUser($loginId: String!, $password: String!) {
    loginUser(loginId: $loginId, password: $password) {
      ok
      message
      accessToken
      refreshToken
      user {
        id
        loginId
        loginName
        userLevel
      }
    }
  }
`;

const Login: React.VoidFunctionComponent = () => {
  const { Text } = Typography;
  const [loginId, setLoginId] = useState<string>('ncp_manager');
  const [password, setPassword] = useState<string>('');
  const [actionMutation, { data }] = useMutation(LOGIN_USER);

  const authenticate = async (): Promise<void> => {
    const params = { loginId, password };
    try {
      await actionMutation({ variables: params });
    } catch (e) {
      message.error({
        content: `${e}`,
        key: 'login',
      });
    }
  };

  if (data && data.loginUser && data.loginUser.ok) {
    localStorage.setItem('user', JSON.stringify(data.loginUser.user));
    localStorage.setItem('accessToken', data.loginUser.accessToken);
    localStorage.setItem('refreshToken', data.loginUser.refreshToken);
    window.location.href = '/';
  }

  return (
    <Paper style={{ maxWidth: 552, margin: 'auto', background: '#030e22' }}>
      <FlexColumn
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingTop: 30,
        }}>
        <div>
          <img src={Logo} alt="Logo" height={100} />
          <Text
            type="secondary"
            style={{
              fontSize: '14px',
              lineHeight: '22px',
              display: 'block',
              margin: '10px',
            }}>
            Ant Design is the most influential web design specification in Xihu
            district
          </Text>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={authenticate}
            onFinishFailed={() => undefined}
            autoComplete="off"
            wrapperCol={{ offset: 4, span: 16 }}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Нэвтрэх нэрийг сонгоно уу',
                },
              ]}>
              <UserSelect onChange={(value: string) => setLoginId(value)} />
            </Form.Item>

            <div style={{ margin: '10px 0' }}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Нууц үгээ оруулна уу!',
                  },
                ]}>
                <Input.Password
                  placeholder="Нууц үг"
                  prefix={<LockOutlined style={{ color: '#030e22' }} />}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            </div>

            <Form.Item style={{ textAlign: 'left' }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ background: 'grey' }}>
                Нэвтрэх
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: 'left' }}>
              <a href="forgot">Нууц үгээ мартсан уу?</a>
            </Form.Item>
          </Form>
        </div>
      </FlexColumn>
    </Paper>
  );
};

export default Login;
