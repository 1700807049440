import React from 'react';
import { Button, Layout, Popover } from 'antd';
import {
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

const { Header: AntHeader } = Layout;

const Titles = [
  {
    route: '/to-confirm-task',
    title: 'Хийсэн ажлууд',
  },
  {
    route: '/completed-task',
    title: 'Хүлээлцсэн ажлууд',
  },
  {
    route: '/task',
    title: 'Захиалга',
  },
  {
    route: '/detail',
    title: 'Ажлын мэдээлэл',
  },
  {
    route: '/summary',
    title: 'Mэдээ',
  },
  {
    route: '/report',
    title: 'Тайлан',
  },
  {
    route: '/team',
    title: 'Баг, ажилчид',
  },
  {
    route: '/work-category',
    title: 'Ажилбар',
  },
  {
    route: '/branch',
    title: 'Салбар',
  },
  {
    route: '/setting',
    title: 'Тохируулга',
  },
  {
    route: '/holiday',
    title: 'Амралтын өдрүүд',
  },
  {
    route: '/staff',
    title: 'Менежерүүд',
  },
  {
    route: '/',
    title: 'Хянах самбар',
  },
];

const ContextMenu: React.VoidFunctionComponent = () => {
  const history = useHistory();

  const onLogout = (): void => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Button
          type="link"
          icon={<SettingOutlined />}
          onClick={() => history.push('/setting')}>
          Нүүц үг солих
        </Button>
      </div>
      <div>
        <Button type="link" icon={<LogoutOutlined />} onClick={onLogout}>
          Гарах
        </Button>
      </div>
    </div>
  );
};

const Header: React.VoidFunctionComponent = () => {
  const location = useLocation();
  const header = Titles.find((v) => location.pathname.startsWith(v.route)) || {
    title: '',
  };

  return (
    <>
      {header.title && (
        <AntHeader
          style={{
            padding: 0,
            background: 'inherit',
            fontSize: 34,
            fontWeight: 'bold',
            marginBottom: 0,
          }}>
          {header.title}
        </AntHeader>
      )}
      <div style={{ position: 'absolute', right: 20, top: 24 }}>
        <Popover
          placement="bottomRight"
          content={<ContextMenu />}
          trigger="click">
          <Button
            title="Logout"
            shape="circle"
            icon={<UserOutlined style={{ fontSize: 22 }} />}
            size="large"
          />
        </Popover>
      </div>
    </>
  );
};

export default Header;
