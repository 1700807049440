import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Input, message, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import React from 'react';
import { Label, Paper, Table } from '../Common';
import DatePicker from '../Common/DatePicker';
import { FlexRow } from '../Common/Styled';

const { TextArea } = Input;

const GET_HOLIDAYS = gql`
  query {
    holidays {
      id
      day
      description
    }
  }
`;

const ADD_HOLIDAY = gql`
  mutation createHoliday($day: String!, $description: String) {
    createHoliday(input: { day: $day, description: $description }) {
      ok
      message
    }
  }
`;

const EDIT_HOLIDAY = gql`
  mutation updateHoliday($id: Int!, $day: String!, $description: String) {
    updateHoliday(id: $id, input: { day: $day, description: $description }) {
      ok
      message
    }
  }
`;

const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($id: Int!) {
    deleteHoliday(id: $id) {
      ok
      message
    }
  }
`;

interface Holiday extends AiType {
  day: Date;
  description?: string;
}

const Holiday: React.VoidFunctionComponent = () => {
  const [selected, setSelected] = React.useState<Holiday>();
  const [date, setDate] = React.useState<Date | undefined>(undefined);
  const [description, setDescription] = React.useState<string>();
  const [days, setDays] = React.useState<Holiday[]>([]);
  const { error: fetchError, data, refetch, loading } = useQuery(GET_HOLIDAYS);
  const [mutate, { error: mutationError, loading: mutationLoading }] =
    useMutation(selected ? EDIT_HOLIDAY : ADD_HOLIDAY);
  const [deleteHoliday, { error: deleteError }] = useMutation(DELETE_HOLIDAY);

  React.useEffect(() => {
    if (loading) {
      message.loading('Уншиж байна...');
    } else if (fetchError || mutationError || deleteError) {
      message.error('Алдаа гарлаа');
    } else if (data) {
      message.destroy();
      setDays(data.holidays.map((d: Holiday) => ({ ...d, key: d.id })));
    }
  }, [loading, data]);

  const clear = () => {
    setDate(undefined);
    setDescription(undefined);
    setSelected(undefined);
  };

  const onEdit = (holiday: Holiday) => {
    setSelected(holiday);
    if (holiday.day) {
      setDate(new Date(holiday.day));
      setDescription(holiday.description);
    }
  };

  const onDelete = (holiday: Holiday) => {
    deleteHoliday({ variables: { id: holiday.id } }).then(() => {
      refetch();
    });
  };

  const onSelectDate = (v: Date) => {
    setDate(v);
  };

  const onSubmit = () => {
    if (date) {
      mutate({
        variables: {
          id: selected?.id,
          day: format(date, 'yyyy-MM-dd'),
          description,
        },
      }).then(() => {
        refetch();
        setDate(undefined);
        setSelected(undefined);
        setDescription('');
      });
    }
  };

  const renderActions = (item: Holiday) => (
    <FlexRow style={{ justifyContent: 'center' }}>
      <Tooltip title="Засварлах">
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => onEdit(item)}
        />
      </Tooltip>
      <Tooltip title="Устгах">
        <Popconfirm
          placement="bottomLeft"
          title="Уг амралтийн өдрийг устгах уу?"
          onConfirm={() => onDelete(item)}
          okText="Тийм"
          cancelText="Үгүй">
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Tooltip>
    </FlexRow>
  );

  const columns: ColumnsType<Holiday> = [
    {
      title: 'Огноо',
      render: (v: Holiday) =>
        v.day ? format(new Date(v.day), 'yyyy-MM-dd') : '',
      width: 120,
    },
    {
      title: 'Тайлбар',
      dataIndex: 'description',
    },
  ];

  return (
    <Paper>
      <FlexRow style={{ alignItems: 'flex-top', maxWidth: 1100 }}>
        <FlexRow style={{ flex: 1, flexWrap: 'wrap' }}>
          <Table<Holiday>
            columns={columns}
            data={days}
            action={renderActions}
            pagination={false}
          />
        </FlexRow>
        <div style={{ width: 400 }}>
          <Label>Амралтын өдөр</Label>
          <DatePicker
            placeholder="Огноо сонгох"
            style={{ width: '100%' }}
            value={date}
            onSelect={onSelectDate}
          />
          <Label>Тэмдэглэл</Label>
          <TextArea
            rows={3}
            value={description}
            onChange={(v) => setDescription(v.target.value)}
          />
          <FlexRow style={{ justifyContent: 'flex-end', marginTop: 10 }}>
            <Button onClick={clear} style={{ marginRight: 5 }}>
              Цэвэрлэх
            </Button>
            <Button
              type="primary"
              loading={mutationLoading}
              onClick={onSubmit}
              icon={selected ? <EditOutlined /> : <PlusOutlined />}>
              {selected ? 'Засах' : 'Нэмэх'}
            </Button>
          </FlexRow>
        </div>
      </FlexRow>
    </Paper>
  );
};

export default Holiday;
