import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Select } from 'antd';

const { Option } = Select;

interface Props {
  onChange: (value: string) => void;
  aimagId?: string;
  defaultValue?: string;
  [_: string]: unknown;
}

const DISTRICTS = gql`
  query {
    districts {
      id
      name
      code
      aimag {
        id
      }
    }
  }
`;

const SelectDistrict: React.FunctionComponent<Props> = ({
  onChange,
  aimagId,
  defaultValue,
  ...rest
}: Props) => {
  const { data, loading } = useQuery(DISTRICTS);
  const [districts, setDistricts] = React.useState<District[]>([]);

  React.useEffect(() => {
    let duureg: District[] = [];
    if (data && aimagId) {
      duureg = data.districts.map((item: District, index: number) => ({
        ...item,
        key: `ci-${index}`,
      }));
      if (aimagId) {
        duureg = duureg.filter((v) => +v.aimag?.id === +aimagId);
      }
    }
    setDistricts(duureg);
  }, [data, aimagId]);

  return (
    <Select<string>
      loading={loading}
      style={{ width: '100%' }}
      defaultValue={defaultValue}
      onChange={onChange}
      {...rest}>
      {districts.map((d: District, i: number) => (
        <Option key={`option-${i}`} value={d.id}>
          {d.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectDistrict;
