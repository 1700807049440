import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Select } from 'antd';

const { Option } = Select;

interface Props {
  onChange: (value: string) => void;
  aimagId?: string;
  districtId?: string;
  [_: string]: unknown;
}

const BRANCHES = gql`
  query {
    branches {
      id
      name
      code
      aimag {
        id
      }
      district {
        id
      }
    }
  }
`;

const SelectBranch: React.FunctionComponent<Props> = ({
  onChange,
  aimagId,
  districtId,
  ...rest
}: Props) => {
  const { data, loading } = useQuery(BRANCHES);
  const [salbars, setSalbars] = React.useState<Branch[]>([]);

  React.useEffect(() => {
    let branches: Branch[] = [];
    if (data) {
      branches = data.branches.map((item: Branch, index: number) => ({
        ...item,
        key: `ci-${index}`,
      }));
    }
    if (aimagId) {
      branches = branches.filter((v) => +v.aimag?.id === +aimagId);
    }
    if (districtId) {
      branches = branches.filter((v) => +v.district?.id === +districtId);
    }
    setSalbars(branches);
  }, [data, aimagId, districtId]);

  return (
    <Select<string>
      style={{ width: '100%' }}
      onChange={onChange}
      {...rest}
      loading={loading}>
      {salbars.map((branch: Branch, i: number) => (
        <Option key={`branch-${i}`} value={branch.id}>
          {branch.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectBranch;
