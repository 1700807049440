import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

type Props = {
  label: string | undefined;
  [_: string]: unknown;
};

export const Label = styled.div`
  color: #8e95a0;
  font-size: 13px;
  margin-top: 5px;
`;

export const LabeledInput: React.FunctionComponent<Props> = (props: Props) => {
  const { label, ...rest } = props;
  return (
    <>
      <Label>{label}</Label>
      <Input {...rest} />
    </>
  );
};
