import React from 'react';
import { Select } from 'antd';
import { TASK_STATUS } from './utils';

const { Option } = Select;

interface Props {
  onChange: (value: number) => void;
  defaultValue?: number;
  [_: string]: unknown;
}

const SelectStatus: React.FunctionComponent<Props> = ({
  onChange,
  defaultValue,
  ...rest
}: Props) => {
  return (
    <Select<number>
      style={{ width: '100%' }}
      defaultValue={defaultValue}
      onChange={onChange}
      {...rest}>
      {TASK_STATUS.map((v) => (
        <Option key={`option-${v.id}`} value={v.id}>
          {v.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectStatus;
