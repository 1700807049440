import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { message } from 'antd';
import { format } from 'date-fns';
import { Paper } from '../Common';
import { FlexRow } from '../Common/Styled';
import Monthly from './Monthly';
import MainTable from './MainTable';
import { extractByTypes } from '../Common/utils';

export const GET_SUMMARY = gql`
  query ($startDate: String!, $endDate: String!) {
    taskSummary(startDate: $startDate, endDate: $endDate) {
      reportCount
      workDays
      all {
        branchCount
        averagePerDay
      }
      completed {
        totalCount
        averagePerDay
      }
      byType {
        name
        daily
        monthly
      }
      incompleteCount
      uncontractedCount
      averageDaysCompleted
    }
  }
`;

const Dashboard: React.VoidFunctionComponent = () => {
  const currentMonth = format(new Date(), 'yyyy-MM');
  const [summary, setSummary] = React.useState<SummaryType>();
  const [summed, setSummed] = React.useState<Turul[]>([
    { name: '-', daily: 0, monthly: 0 },
  ]);
  const { loading, data, error } = useQuery(GET_SUMMARY, {
    variables: { startDate: currentMonth, endDate: currentMonth },
  });

  React.useEffect(() => {
    if (loading) {
      message.info('Уншиж байна...');
    } else if (error) {
      message.error('Алдаа гарлаа');
    } else if (data && data.taskSummary) {
      message.destroy();
      const summaries = data.taskSummary as SummaryType[];
      if (summaries && summaries.length > 0) {
        setSummary(summaries[0]);
        const summedByGroup = extractByTypes(summaries);
        setSummed(summedByGroup);
      }
    }
  }, [data]);

  return (
    <Paper>
      <FlexRow>
        <MainTable byType={summed} />
        <div style={{ marginTop: 33 }}>
          <FlexRow>
            <Monthly
              summary={summary}
              names={summary?.byType.map((v) => v.name)}
              title={currentMonth}
            />
          </FlexRow>
        </div>
      </FlexRow>
    </Paper>
  );
};

export default Dashboard;
