import { intervalToDuration } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import axios from 'axios';
import { message } from 'antd';

export const renderDateFull = (date?: string): string => {
  if (date) {
    const timeZone = 'Asia/Ulaanbaatar';
    return formatInTimeZone(
      new Date(`${date}.000Z`),
      timeZone,
      'yyyy-MM-dd HH:mm',
    );
  }
  return '';
};

export const renderDate = (date?: string): string => {
  if (date) {
    const timeZone = 'Asia/Ulaanbaatar';
    return formatInTimeZone(new Date(`${date}.000Z`), timeZone, 'yyyy-MM-dd');
  }
  return '';
};

/*
  SummaryType-uudaas byType-uudiig avaad name-aar 
  groupleed sum hiigeed shine array uusgene
*/
export const extractByTypes = (summaries: SummaryType[]): Turul[] => {
  if (summaries.length === 0) {
    return [{ name: '-', daily: 0, monthly: 0 }];
  }

  let monthlyTotalSum = 0;
  const summedByGroup = summaries
    .flatMap((v) => v.byType)
    .reduce<Turul[]>((acc, curr) => {
      const { name, daily, monthly } = curr;
      const found = acc.find((v) => v.name === name);
      if (found) {
        found.monthly += monthly;
        found.daily += daily;
        monthlyTotalSum += monthly;
      } else {
        acc.push({ name, daily, monthly });
        monthlyTotalSum += monthly;
      }
      return acc;
    }, []);

  // Make it percentage
  return summedByGroup.map((v) => ({
    ...v,
    monthly: (v.monthly * 100) / monthlyTotalSum || 0,
  }));
};

// +----+--------------+------------+
// | id | login_id     | user_level |
// +----+--------------+------------+
// |  1 | admin        |          0 |
// |  2 | ncp_manager  |          1 |
// |  3 | ncp_staff    |          2 |
// |  4 | bank_manager |          3 |
// |  5 | bank_staff   |          4 |
// +----+--------------+------------+

// Only user level: admin, bank_manager, bank_staff
export const canAddTask = (): boolean => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return [0, 3, 4].includes(+user?.userLevel);
};

export const canEditTimeStatus = (): boolean => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return [0, 1, 2].includes(+user?.userLevel);
};

export const canEditOtherInfo = (): boolean => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return [0, 1, 2].includes(+user?.userLevel);
};

export const canAddOrEditBranch = (): boolean => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return [0, 1, 3].includes(+user?.userLevel);
};

export const canApproveTask = (): boolean => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return [0, 3].includes(+user?.userLevel);
};

export const canManageTeams = (): boolean => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return +user?.userLevel === 1;
};

export const canManageStaff = (): boolean => {
  const user = JSON.parse(localStorage.getItem('user') as string);
  return +user?.userLevel === 1;
};

// Print a number with commas as thousands separators
export function addSeparator(x: number | undefined): string {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  return '0';
}

export const convertSeconds = (seconds?: number): string => {
  if (seconds) {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    return `${duration.hours} цаг ${duration.minutes} минут`;
  }
  return '-';
};

// Extract 2 numeric values from string in format (num1:num2)
export const extractLocation = (coordinat?: string): number[] | undefined[] => {
  if (coordinat && coordinat.includes(':')) {
    const [lat, lng] = coordinat.split(':');
    if (!Number.isNaN(+lat) && !Number.isNaN(+lng)) {
      return [+lat, +lng];
    }
  }
  return [undefined, undefined];
};

// Upload given image file into AWS S3 pre-signed URLs
export const uploadToS3 = (image: S3ImageUploadType): Promise<ImageType> => {
  const presignedPostUrl = JSON.parse(image.presignedUrl.replace(/'/g, '"'));
  const formData = new FormData();
  Object.entries(presignedPostUrl.fields).forEach(([key, value]) => {
    formData.append(key, value as string);
  });
  // The file has to be the last element
  formData.append('file', image.file.originFileObj as File);

  return axios
    .post(presignedPostUrl.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      // onUploadProgress: (progressEvent) =>
      //   console.log('Progress: ', progressEvent.loaded),
    })
    .then(() => {
      return {
        note: image.note,
        filename: presignedPostUrl.fields.key,
      };
    });
};

// Upload given all images into AWS S3 presigned urls
export const uploadImages = (
  images: S3ImageUploadType[],
): Promise<ImageType[]> => {
  const promises: Promise<ImageType>[] = [];
  if (images.length > 0) message.loading('Хуулж байна');
  images.forEach((image) => {
    promises.push(uploadToS3(image));
  });
  return Promise.all(promises);
};

export const sortDates = (a?: Date, b?: Date): number => {
  const timestamp_a = a ? new Date(a).getTime() : 0;
  const timestamp_b = b ? new Date(b).getTime() : 0;
  return timestamp_a - timestamp_b;
};
