import React from 'react';
import 'antd/dist/antd.css';
import { Form, Input, Button, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Paper } from '../Common/Paper';
import { FlexColumn } from '../Common/Styled';
import Logo from '../../assets/ncp-logo.png';

const ForgetPassword: React.VoidFunctionComponent = () => {
  const { Text } = Typography;

  return (
    <Paper style={{ maxWidth: 552, margin: 'auto', background: '#030e22' }}>
      <FlexColumn
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
        <div>
          <img src={Logo} alt="Logo" height={100} />

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={() => undefined}
            onFinishFailed={() => undefined}
            autoComplete="off"
            style={{ maxWidth: 360, margin: 'auto' }}>
            <Form.Item style={{ maxWidth: 335 }}>
              <Text
                style={{
                  fontSize: '24px',
                }}>
                Та нууц үгээ мартсан уу?
              </Text>
              <Text
                type="secondary"
                style={{
                  fontSize: '14px',
                  display: 'block',
                  color: '#f0f2f5',
                }}>
                Бүртгүүлсэн имэйл хаягаа оруулна уу.
                <br />
                Бид танд нууц үгээ шинэчлэх код илгээх болно.
              </Text>
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}>
              <Input
                placeholder="Email"
                prefix={<MailOutlined style={{ color: '#030e22' }} />}
              />
            </Form.Item>

            <Form.Item style={{ textAlign: 'left' }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{ background: 'grey' }}>
                Нууц үгээ сэргээх
              </Button>
            </Form.Item>
          </Form>
        </div>
      </FlexColumn>
    </Paper>
  );
};

export default ForgetPassword;
