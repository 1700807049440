import React from 'react';
import styled from 'styled-components';
import { Paper } from '../Common';
import { FlexColumn, FlexRow } from '../Common/Styled';
import { TASK_STATUS, renderBranch } from './utils';
import { convertSeconds, renderDate } from '../Common/utils';

export const Padded = styled(FlexRow)`
  padding: 3px 0;
  white-space: nowrap;
`;

export const Bold = styled(Padded)`
  font-weight: bold;
`;

type Props = {
  selected?: Task;
  children?: React.ReactNode;
};

function TaskInfo({ selected, children }: Props): React.ReactElement {
  const status = TASK_STATUS.find((v) => v.id === selected?.status);
  const lastRecord = selected?.taskDetails?.slice(-1)[0];

  return (
    <Paper style={{ padding: '10px 15px' }}>
      <FlexRow>
        <FlexColumn style={{ maxWidth: 130, minWidth: 130 }}>
          <Bold>Захиалгын код</Bold>
          <Bold>Бүртгэсэн</Bold>
          {selected?.isEmergency ? (
            <Bold style={{ minWidth: 100, height: 28 }}> </Bold>
          ) : null}
          <Bold style={{ marginBottom: '20px' }}>Салбар</Bold>
          <Bold>Төлөв</Bold>
        </FlexColumn>
        <FlexColumn>
          <Padded>{`${selected?.code || '?'}`}</Padded>
          <Padded style={{ whiteSpace: 'nowrap' }}>
            {selected?.registeredAt &&
              renderDate(selected?.registeredAt.toString())}
            {`, ${selected?.user?.loginName}`}
          </Padded>
          {selected?.isEmergency ? (
            <Padded style={{ color: 'red' }}>Яаралтай дуудлага</Padded>
          ) : null}
          <Padded style={{ whiteSpace: 'nowrap' }}>
            {renderBranch(selected)}
          </Padded>
          <Padded>{(status && status.name) || '-'}</Padded>
        </FlexColumn>
        <FlexColumn style={{ marginLeft: '10%', maxWidth: 130, minWidth: 130 }}>
          <Bold>Хүний тоо</Bold>
          <Bold>Ажлын цагаар</Bold>
          <Bold>Илүү цагаар</Bold>
          <Bold>Ажилбар</Bold>
        </FlexColumn>
        <FlexColumn>
          <Padded>{lastRecord?.numofPr || 1}</Padded>
          <Padded>{convertSeconds(selected?.spentTime)}</Padded>
          <Padded>{convertSeconds(selected?.overTime)}</Padded>
          <Padded style={{ whiteSpace: 'normal' }}>
            {selected?.workCategories?.map((item) => item.shortName).join(', ')}
          </Padded>
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <Bold style={{ maxWidth: 130, minWidth: 130 }}>Ажлын тайлбар</Bold>
        <Padded style={{ whiteSpace: 'normal' }}>
          {selected?.description || '-'}
        </Padded>
      </FlexRow>
      {children}
    </Paper>
  );
}

export default TaskInfo;
