import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Input, message } from 'antd';
import TaskInfo from './TaskInfo';
import { Paper, Modal, Label } from '../Common';
import { FlexRow } from '../Common/Styled';
import ExpenseControl from './ExpenseControl';
import { uploadImages } from '../Common/utils';
import ImageUploadOne from '../Common/ImageUploadOne';

const { TextArea } = Input;

const UPDATE_EXPENSES = gql`
  mutation updateTaskExpense(
    $id: Int!
    $expenseNote: String!
    $images: [ImageInput]
    $expenses: [ExpenseInput]!
  ) {
    updateTaskExpense(
      id: $id
      input: { expenseNote: $expenseNote, images: $images, expenses: $expenses }
    ) {
      ok
      message
    }
  }
`;

interface TaskInputType {
  id?: string;
  expenseNote?: string;
}

function ModalExpenses({
  selected,
  visible,
  onOk,
  onCancel,
}: ModalProps<Task>): React.ReactElement {
  const [pristine, setPristine] = useState(true);
  const [saving, setSaving] = useState(false);
  const [dump, setDump] = useState({});
  const [doc, setDoc] = useState<TaskInputType>({});
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [updateExpenses, { error }] = useMutation(UPDATE_EXPENSES);

  React.useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const onSubmit = () => {
    const params = { ...doc };

    if (selected) {
      params.id = selected.id;
    }

    setSaving(true);
    const uploads = Object.values(dump).filter((v) => v) as S3ImageUploadType[];
    uploadImages(uploads).then((results) => {
      updateExpenses({ variables: { ...params, images: results, expenses } })
        .then(() => {
          message.success('Захиалга амжилттай засагдлаа');
          setPristine(true);
          setSaving(false);
          onOk();
        })
        .catch((raw) => {
          const parsedError = JSON.parse(JSON.stringify(raw));
          message.error(parsedError.message);
        });
    });
  };

  const setDocValue = (name: string, value: string | number) => {
    setPristine(false);
    setDoc({ ...doc, [name]: value });
  };

  React.useEffect(() => {
    setDoc({ ...doc, expenseNote: selected?.expenseNote || '' });
    setPristine(true);
  }, [visible, selected?.id]);

  return (
    <Modal
      key={`task-${selected?.id}`}
      title="Зардлын мэдээлэл засах"
      visible={visible}
      width={1000}
      onOk={onSubmit}
      onCancel={onCancel}
      canDelete={false}
      item={selected}
      values={[{ name: 'note', value: doc.expenseNote }]}
      pristine={pristine}
      saving={saving}
      submitText="Хадгалах">
      <TaskInfo selected={selected} />
      <Paper>
        <FlexRow>
          <div style={{ flex: 4, marginRight: 15 }}>
            <ExpenseControl task={selected} onChange={setExpenses} />
          </div>
          <div style={{ flex: 2 }}>
            <Label>Тэмдэглэл</Label>
            <TextArea
              rows={5}
              value={doc?.expenseNote}
              onChange={(e) =>
                setDocValue('expenseNote', e.currentTarget.value)
              }
            />
            <Label>Зураг оруулах</Label>
            {[0, 1].map((i) => (
              <ImageUploadOne
                key={i}
                onChooseImage={(imageObj) => {
                  setPristine(false);
                  setDump({ ...dump, [i]: imageObj });
                }}
              />
            ))}
          </div>
        </FlexRow>
      </Paper>
    </Modal>
  );
}

export default ModalExpenses;
