import styled from 'styled-components';

type StyleProps = {
  style?: React.CSSProperties;
};

export const FlexRow = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
`;
export const FlexWrap = styled.div<StyleProps>`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageRound = styled.img<StyleProps>`
  border-radius: 12px;
  width: 100%;
`;

export const DateLabel = styled.div<StyleProps>`
  color: #9198a3;
  font-size: 16px;
  margin-right: 4px;
  display: inline;
`;
