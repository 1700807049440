import React from 'react';
import { Layout } from 'antd';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Routes from './Routes';
import Menu from './Layout/Menu';
import Header from './Layout/Header';
import { MenuConstants } from './Common';
import Login from './Login';

const { Content, Footer } = Layout;
const { width, collapsedWidth } = MenuConstants;

const App: React.VoidFunctionComponent = () => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const authenticated = localStorage.getItem('user');

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const accessToken = localStorage.getItem('accessToken') || '';
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  if (authenticated) {
    return (
      <ApolloProvider client={client}>
        <Layout>
          <Menu onCollapsed={(value) => setCollapsed(value)} />
          <Layout
            className="site-layout"
            style={{
              marginLeft: collapsed ? collapsedWidth : width,
            }}>
            <Header />
            <Content style={{ margin: 0, overflow: 'initial' }}>
              <Routes />
            </Content>
            <Footer style={{ textAlign: 'center' }}>© 2022 NCProperty</Footer>
          </Layout>
        </Layout>
      </ApolloProvider>
    );
  }

  return (
    <ApolloProvider client={client}>
      <Login />
    </ApolloProvider>
  );
};

export default App;
