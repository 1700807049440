import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Select, Divider, Input } from 'antd';

const { Option } = Select;

interface Props {
  onChange: (value: string) => void;
  [_: string]: unknown;
}

const USERS = gql`
  query {
    users {
      loginId
      loginName
      userLevel
    }
  }
`;

const UserSelect: React.FunctionComponent<Props> = ({
  onChange,
  ...rest
}: Props) => {
  const [manualUserName, setManualUserName] = React.useState<string>();
  const [users, setUsers] = React.useState<LoginType[]>([]);
  const { data } = useQuery(USERS);

  React.useEffect(() => {
    if (data) {
      setUsers(
        data.users
          .map((item: LoginType, index: number) => ({
            ...item,
            key: `ci-${index}`,
          }))
          .filter((v: LoginType) => [1, 3].includes(v.userLevel)),
      );
    }
  }, [data]);

  return (
    <Select<string>
      allowClear
      style={{ width: '100%' }}
      onChange={onChange}
      placeholder="Нэвтрэх эрхээ сонгоно уу"
      onClear={() => {
        onChange('');
        setManualUserName('');
      }}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              value={manualUserName}
              onChange={(e) => {
                setManualUserName(e.target.value);
                onChange(e.target.value);
              }}
            />
          </div>
        </div>
      )}
      {...rest}>
      {users.map((c: LoginType, i: number) => (
        <Option key={`option-${i}`} value={c.loginId}>
          {c.loginName}
        </Option>
      ))}
    </Select>
  );
};

export default UserSelect;
