import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Select } from 'antd';

const { Option } = Select;

interface Props {
  onChange: (value: string) => void;
  [_: string]: unknown;
}

const AIMAGUUD = gql`
  query {
    aimaguud {
      id
      name
      code
    }
  }
`;

const SelectAimag: React.FunctionComponent<Props> = ({
  onChange,
  ...rest
}: Props) => {
  const { data, loading } = useQuery(AIMAGUUD);
  const [aimaguud, setAimaguud] = React.useState<Aimag[]>([]);

  React.useEffect(() => {
    if (data) {
      setAimaguud(
        data.aimaguud.map((item: Aimag, index: number) => ({
          ...item,
          key: `ci-${index}`,
        })),
      );
    }
  }, [data]);

  return (
    <Select<string>
      style={{ width: '100%' }}
      onChange={onChange}
      loading={loading}
      {...rest}>
      {aimaguud.map((c: Aimag, i: number) => (
        <Option key={`option-${i}`} value={c.id}>
          {c.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectAimag;
