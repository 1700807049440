import {
  CheckOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  HistoryOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Button, message, Tooltip } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SelectAimag from '../Branch/SelectAimag';
import SelectDistrict from '../Branch/SelectDistrict';
import { Paper, renderDateFull, Table } from '../Common';
import ControlBar from '../Common/ControlBar';
import DatePicker from '../Common/DatePicker';
import { FlexRow } from '../Common/Styled';
import {
  canAddTask,
  canApproveTask,
  canEditOtherInfo,
  canEditTimeStatus,
} from '../Common/utils';
import ModalAccept from './ModalAccept';
import ModalCreate from './ModalCreate';
import ModalExpenses from './ModalExpenses';
import ModalMap from './ModalMap';
import ModalPrint from './ModalPrint';
import ModalTimeState from './ModalTimeState';
import {
  renderBranch,
  renderCreatedBy,
  renderRegisteredAt,
  renderStatus,
  taskColumnsInExcel,
} from './utils';

const TASKS = gql`
  query (
    $kind: Int
    $createdAt: String
    $approved: Boolean
    $aimagId: Int
    $districtId: Int
  ) {
    tasks(
      kind: $kind
      createdAt: $createdAt
      approved: $approved
      aimagId: $aimagId
      districtId: $districtId
    ) {
      id
      code
      createdAt
      registeredAt
      branch {
        id
        name
        aimag {
          name
        }
        district {
          name
        }
        gpsCoordinat
        managerName
        phone
      }
      status
      isEmergency
      workCategories {
        id
        name
        shortName
      }
      description
      user {
        loginName
        phone
      }
      minStartedAt
      maxFinishedAt
      images {
        id
        note
        filename
        url
      }
      approved
      approveNote
      totalExpense
      spentTime
      overTime
      taskDetails {
        startedAt
        finishedAt
        note
        numofPr
      }
    }
  }
`;

type Props = {
  kind?: number;
  approved: boolean;
};

function Task({ kind, approved }: Props): React.ReactElement {
  const [aimagId, setAimagId] = useState<number>();
  const [districtId, setDistrictId] = useState<number>();
  const [selected, setSelected] = useState<Task>();
  const [createdAt, setCreatedAt] = useState<Date | undefined>(new Date());
  const [showOthers, setShowOthers] = useState<boolean>(false);
  const [showTimeState, setShowTimeState] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [showAccept, setShowAccept] = useState<boolean>(false);
  const [showPrint, setShowPrint] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isEmergencyModalVisible, setIsEmergencyModalVisible] =
    useState<boolean>(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const history = useHistory();
  const { pathname } = useLocation();
  const isOngoingTask = pathname === '/task';
  const { loading, data, refetch } = useQuery(TASKS, {
    variables: {
      kind,
      approved,
      createdAt: createdAt ? format(createdAt, 'yyyy-MM') : undefined,
      aimagId,
      districtId,
    },
  });

  const sorter = (a?: string | Date, b?: string | Date) => {
    const timestamp_a = a ? new Date(a).getTime() : 0;
    const timestamp_b = b ? new Date(b).getTime() : 0;
    return timestamp_a - timestamp_b;
  };

  React.useEffect(() => {
    setCreatedAt(new Date());
    refetch();
  }, [kind, approved]);

  React.useEffect(() => {
    let records: Task[] = [];
    if (loading) {
      message.info('Уншиж байна...');
    } else if (data) {
      message.destroy();
      records = data.tasks.map((item: Task, index: number) => ({
        ...item,
        key: `ci-${index}`,
      }));
      if (searchKey) {
        const key = searchKey.toLocaleLowerCase();
        try {
          records = records.filter(
            (task) =>
              task.branch.aimag.name.toLocaleLowerCase().includes(key) ||
              task.description.toLocaleLowerCase().includes(key) ||
              task.branch.district.name.toLocaleLowerCase().includes(key) ||
              task.branch.name.toLocaleLowerCase().includes(key),
          );
        } catch (error) {
          message.warn('Хайлт амжилтгүй боллоо');
        }
      }
    }
    // Sort if displays COMPLETED TASKS
    if (!kind)
      records.sort((a: Task, b: Task) =>
        sorter(b.registeredAt, a.registeredAt),
      );
    setTasks(records);
  }, [loading, data, searchKey]);

  const renderActions = (item: Task) => (
    <FlexRow style={{ alignItems: 'center', justifyContent: 'space-around' }}>
      {!item.approved && canEditTimeStatus() && (
        <Tooltip title="Цаг төлөв өөрчлөх">
          <Button
            type="link"
            icon={<HistoryOutlined style={{ fontSize: 22 }} />}
            onClick={(e) => {
              setShowTimeState(true);
              setSelected(item);
              e.stopPropagation();
            }}
          />
        </Tooltip>
      )}
      {!item.approved && canEditOtherInfo() && (
        <Tooltip title="Зардлын мэдээлэл засах">
          <Button
            type="link"
            icon={<DollarCircleOutlined style={{ fontSize: 22 }} />}
            onClick={(e) => {
              setShowOthers(true);
              setSelected(item);
              e.stopPropagation();
            }}
          />
        </Tooltip>
      )}
      {canApproveTask() && kind === 2 && approved === false && (
        <Tooltip title="Хүлээн авах">
          <Button
            type="link"
            onClick={(e) => {
              setSelected(item);
              setShowAccept(true);
              e.stopPropagation();
            }}
            icon={<CheckOutlined style={{ fontSize: 22 }} />}
          />
        </Tooltip>
      )}
      {approved && (
        <Tooltip title="Баримт хэвлэх">
          <Button
            type="link"
            onClick={(e) => {
              setSelected(item);
              setShowPrint(true);
              e.stopPropagation();
            }}
            icon={<PrinterOutlined style={{ fontSize: 22 }} />}
          />
        </Tooltip>
      )}
    </FlexRow>
  );

  const showModal = () => {
    setSelected(undefined);
    setIsModalVisible(true);
  };

  const showEmergencyModal = () => {
    setSelected(undefined);
    setIsEmergencyModalVisible(true);
  };

  const columns: ColumnsType<Task> = [
    {
      title: 'Код',
      render: function AimagName(task: Task) {
        return (
          <Button
            type="text"
            shape="circle"
            title={task.isEmergency ? 'Яаралтай' : ''}
            onClick={() => history.push(`/detail/${task?.id}`)}>
            {task.code}
          </Button>
        );
      },
    },
    {
      title: 'Огноо',
      render: renderRegisteredAt,
      sorter: (a: Task, b: Task) => sorter(a.registeredAt, b.registeredAt),
      width: 140,
    },
    {
      title: 'Салбар',
      render: renderBranch,
    },
    {
      title: 'Ажлын тайлбар',
      dataIndex: 'description',
    },
    {
      title: 'Ажилбар',
      render: function Status(task: Task) {
        return (
          <>
            {task.workCategories?.map((v, i) => (
              <div key={i}>{v.shortName}</div>
            ))}
          </>
        );
      },
    },
    {
      title: 'Төлөв',
      render: renderStatus,
      width: 100,
    },
    {
      key: 'minStartedAt',
      title: 'Эхэлсэн',
      width: 130,
      sorter: (a: Task, b: Task) => sorter(a.minStartedAt, b.minStartedAt),
      render: function RenderStartDate(task: Task) {
        if (task && task.minStartedAt) {
          return (
            <div style={{ textAlign: 'center' }}>
              <div>{renderDateFull(task.minStartedAt)}</div>
            </div>
          );
        }
      },
    },
    isOngoingTask
      ? {
          title: 'Оруулсан',
          width: 130,
          render: renderCreatedBy,
        }
      : {
          key: 'maxFinishedAt',
          title: 'Дууссан',
          dataIndex: 'maxFinishedAt',
          width: 130,
          sorter: (a: Task, b: Task) =>
            sorter(a.maxFinishedAt, b.maxFinishedAt),
          render: renderDateFull,
        },
  ];

  return (
    <div>
      <ControlBar
        onClickAdd={isOngoingTask && canAddTask() ? showModal : undefined}
        onClickAddEmergency={
          isOngoingTask && canAddTask() ? showEmergencyModal : undefined
        }
        value={searchKey}
        tooltip="Захиалга нэмэх"
        placeholder="Захиалга хайх"
        emergencyTooltip="Яаралтай ослын дуудлага нэмэх"
        emergencyPlaceholder="Ослын дуудлага"
        count={tasks.length}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          setSearchKey(e.currentTarget.value)
        }>
        <div style={{ maxWidth: 130, margin: '0 10px' }}>
          <Tooltip title="Хийгдсэн сараар шүүх">
            <DatePicker
              picker="month"
              format="YYYY - MM"
              onChange={(date) => setCreatedAt(date || undefined)}
              value={createdAt}
              allowClear
              style={{ width: '100%' }}
              size="large"
              placeholder="Хийгдсэн сар"
            />
          </Tooltip>
        </div>
        <div style={{ width: 160, marginRight: 10 }}>
          <Tooltip title="Харьяаллаар шүүх">
            <SelectAimag
              size="large"
              allowClear
              style={{ width: '100%' }}
              placeholder="Харьяалал"
              onChange={(str) => {
                if (str) {
                  setAimagId(+str);
                } else {
                  setAimagId(undefined);
                  setDistrictId(undefined);
                }
              }}
            />
          </Tooltip>
        </div>
        <div style={{ width: 190, marginRight: 10 }}>
          <Tooltip title="Байршлаар шүүх">
            <SelectDistrict
              size="large"
              allowClear
              value={districtId ? `${districtId}` : undefined}
              aimagId={`${aimagId}`}
              style={{ width: '100%' }}
              placeholder="Байршил"
              onChange={(str) => setDistrictId(+str || undefined)}
            />
          </Tooltip>
        </div>
        {kind === 1 && (
          <Tooltip title="Захиалгуудыг газрын зураг дээр харах">
            <Button
              type="primary"
              icon={<EnvironmentOutlined />}
              onClick={() => setShowMap(true)}>
              Тархац
            </Button>
          </Tooltip>
        )}
      </ControlBar>
      <Paper style={{ paddingTop: 6 }}>
        <Table<Task>
          columns={columns}
          data={tasks}
          action={renderActions}
          onRowClick={(task) => history.push(`/detail/${task?.id}`)}
          onDownload={() => {
            const excel = new Excel();
            excel
              .addSheet('Захиалга')
              .setRowHeight(0.5, 'cm')
              .addColumns(taskColumnsInExcel)
              .setTHeadStyle({ bold: true, border: true })
              .addDataSource(tasks)
              .saveAs('Захиалга.xlsx');
          }}
        />
        <ModalCreate
          onCancel={() => setIsModalVisible(false)}
          selected={selected}
          onOk={() => {
            setIsModalVisible(false);
            refetch();
          }}
          visible={isModalVisible}
        />
        <ModalCreate
          onCancel={() => setIsEmergencyModalVisible(false)}
          isEmergency
          onOk={() => {
            setIsEmergencyModalVisible(false);
            refetch();
          }}
          visible={isEmergencyModalVisible}
        />
        <ModalCreate
          onCancel={() => setIsModalVisible(false)}
          selected={selected}
          onOk={() => {
            setIsModalVisible(false);
            refetch();
          }}
          visible={isModalVisible}
        />
        <ModalTimeState
          onCancel={() => setShowTimeState(false)}
          selected={selected}
          onOk={() => {
            setShowTimeState(false);
            refetch();
          }}
          visible={showTimeState}
        />
        <ModalExpenses
          onCancel={() => setShowOthers(false)}
          selected={selected}
          onOk={() => {
            setShowOthers(false);
            refetch();
          }}
          visible={showOthers}
        />
        <ModalMap
          onCancel={() => setShowMap(false)}
          tasks={tasks}
          visible={showMap}
        />
        <ModalAccept
          onCancel={() => setShowAccept(false)}
          selected={selected}
          onOk={() => {
            setShowAccept(false);
            refetch();
          }}
          visible={showAccept}
        />
        <ModalPrint
          onCancel={() => setShowPrint(false)}
          selected={selected}
          onOk={() => setShowPrint(false)}
          visible={showPrint}
        />
      </Paper>
    </div>
  );
}

export default Task;
