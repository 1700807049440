import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, Select, Input, message } from 'antd';
import { Paper, Modal, Label, LabeledInput } from '../Common';
import SelectAimag from './SelectAimag';
import MapControl from './MapControl';
import { FlexRow } from '../Common/Styled';
import BranchDistrict from './BranchDistrict';

const { Option } = Select;
const { TextArea } = Input;

export const BRANCH_TYPE = [
  {
    id: '1',
    name: 'Тооцооны төв',
  },
  {
    id: '2',
    name: 'Экспресс банк',
  },
  {
    id: '3',
    name: 'АТМ',
  },
  {
    id: '4',
    name: 'Зөөврийн АТМ',
  },
  {
    id: '9',
    name: 'Бусад',
  },
];

// prettier-ignore
const ADD_BRANCH = gql`
  mutation createBranch($aimagId: Int!, $districtId: Int!, $name: String!, $code: String!, $type: Int, $description: String, $managerName: String, $phone: String, $gpsCoordinat: String) {
    createBranch(input: { aimagId: $aimagId, name: $name, districtId: $districtId, code: $code, type: $type, description: $description, managerName: $managerName, phone: $phone, gpsCoordinat: $gpsCoordinat }) {
      ok
      message
    }
  }
`;

// prettier-ignore
const UPDATE_BRANCH = gql`
  mutation updateBranch($id: Int!, $aimagId: Int!, $districtId: Int!, $name: String!, $code: String!, $type: Int, $description: String, $managerName: String, $phone: String, $gpsCoordinat: String) {
    updateBranch(id: $id, input: { aimagId: $aimagId, name: $name, districtId: $districtId, code: $code, type: $type, description: $description, managerName: $managerName, phone: $phone, gpsCoordinat: $gpsCoordinat }) {
      ok
      message
    }
  }
`;

const DELETE_BRANCH = gql`
  mutation deleteBranch($id: Int!) {
    deleteBranch(id: $id) {
      ok
      message
    }
  }
`;

interface BranchDoc {
  id?: string;
  aimagId?: string;
  districtId?: string;
  name?: string;
  description?: string;
  code?: string;
  type?: string;
  managerName?: string;
  phone?: string;
  gpsCoordinate?: string;
}

function BranchModal({
  selected,
  visible,
  onOk,
  onCancel,
}: ModalProps<Branch>): React.ReactElement {
  const [doc, setDoc] = React.useState<BranchDoc>({});
  const [actionMutation, { error }] = useMutation(
    selected ? UPDATE_BRANCH : ADD_BRANCH,
  );
  const [actionDeleteMutation] = useMutation(DELETE_BRANCH);

  React.useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const onSubmit = () => {
    const variables = { ...doc };

    if (selected) {
      variables.id = selected.id;
    }

    actionMutation({ variables })
      .then(() => {
        message.success('Амжилттай гүйцэтгэгдлээ');
        onOk();
      })
      .catch((raw) => {
        const parsedError = JSON.parse(JSON.stringify(raw));
        message.error(parsedError.message);
      });
  };

  const onDelete = (item: Branch) => {
    actionDeleteMutation({ variables: { id: item.id } })
      .then(() => {
        message.success('Амжилттай устгагдлаа');
        onOk();
      })
      .catch((raw) => {
        const parsedError = JSON.parse(JSON.stringify(raw));
        message.error(parsedError.message);
      });
  };

  const setDocValue = (name: string, value: string) => {
    setDoc({ ...doc, [name]: value });
  };

  React.useEffect(() => {
    setDoc({
      ...doc,
      aimagId: selected?.aimag?.id || '',
      districtId: selected?.district?.id || '',
      name: selected?.name || '',
      code: selected?.code || '',
      description: selected?.description || '',
      type: `${selected?.type || ''}`,
      managerName: `${selected?.managerName || ''}`,
      phone: `${selected?.phone || ''}`,
    });
  }, [visible, selected?.id]);

  return (
    <Modal
      key={`user-${selected?.id}`}
      title={`Салбар ${selected?.id ? 'засах' : 'нэмэх'}`}
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      onDelete={onDelete}
      width={820}
      item={selected}
      values={[
        { name: 'name', value: doc.name },
        { name: 'type', value: doc.type },
        { name: 'aimag', value: doc.aimagId },
        { name: 'district', value: doc.districtId },
      ]}
      submitText="Хадгалах">
      <Paper>
        <FlexRow>
          <div style={{ flex: 2, marginRight: 18 }}>
            <Label>Харъяалал</Label>
            <Form.Item
              name="aimag"
              rules={[{ required: true, message: 'Заавал cонгоно уу!' }]}>
              <SelectAimag
                className="required"
                onChange={(value: string) => {
                  setDoc({ ...doc, aimagId: value, districtId: '' });
                }}
                required
              />
            </Form.Item>
            <Label>Байршил</Label>
            <Form.Item
              name="district"
              rules={[{ required: true, message: 'Заавал cонгоно уу!' }]}>
              <BranchDistrict
                aimagId={doc.aimagId}
                onChange={(t: string) => {
                  setDocValue('districtId', t);
                }}
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Заавал утга оруулна уу!' }]}>
              <LabeledInput
                label="Нэр"
                className="required"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setDocValue('name', e.currentTarget.value)
                }
              />
            </Form.Item>
            <LabeledInput
              label="Код"
              value={doc?.code}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDocValue('code', e.currentTarget.value)
              }
            />
            <Label>Төрөл</Label>
            <Form.Item
              name="type"
              rules={[{ required: true, message: 'Заавал сонгоно уу!' }]}>
              <Select<string>
                style={{ width: '100%' }}
                className="required"
                onChange={(value) => setDocValue('type', value)}>
                {BRANCH_TYPE.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <LabeledInput
              label="Менежерийн нэр"
              value={doc?.managerName}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDocValue('managerName', e.currentTarget.value)
              }
            />
            <LabeledInput
              label="Холбоо барих утас"
              value={doc?.phone}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setDocValue('phone', e.currentTarget.value)
              }
            />
            <Label>Тайлбар</Label>
            <TextArea
              value={doc?.description}
              rows={3}
              onChange={(e) =>
                setDocValue('description', e.currentTarget.value)
              }
            />
          </div>
          <div style={{ flex: 3 }}>
            <MapControl
              branch={selected}
              onChange={(value) => setDocValue('gpsCoordinat', value)}
            />
          </div>
        </FlexRow>
      </Paper>
    </Modal>
  );
}

export default BranchModal;
