import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import ForgetPassword from './Login/ForgetPassword';
import RegistrationConfirmation from './RegistrationConfirmation';
import Branch from './Branch';
import Task from './Task';
import WorkCategory from './WorkCategory';
import TaskHistory from './Task/TaskHistory';
import Summary from './Summary';
import Settings from './Settings';
import Tailan from './Tailan';
import Holiday from './Holiday';
import Staff from './Staff';

const Routes: React.VoidFunctionComponent = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route exact path="/to-confirm-task">
        <Task kind={2} approved={false} />
      </Route>
      <Route exact path="/completed-task">
        <Task approved />
      </Route>
      <Route exact path="/task">
        <Task kind={1} approved={false} />
      </Route>
      <Route exact path="/detail/:id">
        <TaskHistory />
      </Route>
      <Route exact path="/summary">
        <Summary />
      </Route>
      <Route exact path="/report">
        <Tailan />
      </Route>
      <Route exact path="/work-category">
        <WorkCategory />
      </Route>
      <Route exact path="/branch">
        <Branch />
      </Route>
      {/* <Route exact path="/team">
        <Team />
      </Route> */}
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/forgot">
        <ForgetPassword />
      </Route>
      <Route exact path="/setting">
        <Settings />
      </Route>
      <Route exact path="/confirm/:token">
        <RegistrationConfirmation />
      </Route>
      <Route exact path="/holiday">
        <Holiday />
      </Route>
      <Route exact path="/staff">
        <Staff />
      </Route>
    </Switch>
  );
};

export default Routes;
