import React, { useState } from 'react';
import { Input, Upload, message } from 'antd';
import { useLazyQuery, gql } from '@apollo/client';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { FlexRow } from './Styled';

const { TextArea } = Input;

const GET_PRESIGNED_URL = gql`
  query {
    imageUploadPresignedUrl
  }
`;

export type ImagePreview = {
  uid: number;
  name: string;
  status: string;
  ulr: string;
};

type UploadProps = {
  onChooseImage: (image?: S3ImageUploadType) => void;
  id?: number;
  imageObj?: ImageType;
};

function ImageUploadOne({
  onChooseImage,
  id,
  imageObj,
}: UploadProps): React.ReactElement {
  const [uploadImage, setUploadImage] = useState<UploadFile>();
  const [note, setNote] = useState<string | undefined>(imageObj?.note);
  const [uploadUrl, setUploadUrl] = useState<string>();
  const [getPresignedUrl, { data, error }] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache', // Used for subsequent executions
  });

  const isValidFile = (file: UploadFile | File): boolean => {
    const isImage = !!file?.type?.includes('image');
    return isImage;
  };

  const onChange = (param: UploadChangeParam) => {
    if (isValidFile(param.file)) {
      if (param.file.status !== 'removed') setUploadImage(param.file);
    } else {
      message.warn('Зөвхөн зураг сонгоно уу');
    }
  };

  const onClick = (file: File) => {
    if (isValidFile(file) || !uploadUrl) {
      getPresignedUrl();
    }
    return '';
  };

  React.useEffect(() => {
    if (imageObj) {
      setUploadImage({
        uid: imageObj.id || '',
        status: 'done',
        url: imageObj.url,
        name: imageObj.filename || '',
      });
    } else {
      setUploadImage(undefined);
    }
    setUploadUrl(undefined);
  }, [id, imageObj]);

  React.useEffect(() => {
    if (error) {
      message.error('Presigned үүсгэхэд алдаа гарлаа');
    } else if (data && uploadImage) {
      message.destroy();
      setUploadUrl(data.imageUploadPresignedUrl);
      onChooseImage({
        file: uploadImage,
        presignedUrl: data.imageUploadPresignedUrl,
        note,
      });
      // console.log({
      //   file: uploadImage,
      //   presignedUrl: data.imageUploadPresignedUrl,
      //   note,
      // });
    }
  }, [error, data]);

  const onPreview = async (file: UploadFile) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as Blob);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src || '';
    const imgWindow = window.open(src);
    if (imgWindow) imgWindow.document.write(image.outerHTML);
  };

  return (
    <FlexRow
      id={`${id}`}
      style={{ width: '100%', minWidth: 120, alignItems: 'center' }}>
      <div>
        <Upload
          action={onClick}
          listType="picture-card"
          fileList={uploadImage ? [uploadImage] : []}
          onChange={onChange}
          onRemove={() => {
            setUploadImage(undefined);
            onChooseImage(undefined);
          }}
          onPreview={onPreview}>
          {!uploadImage && (
            <div style={{ color: 'grey', fontSize: 12 }}>+ Сонгох</div>
          )}
        </Upload>
      </div>
      <div style={{ flex: 1 }}>
        <TextArea
          autoSize={false}
          value={note}
          placeholder="Зургийн тайлбар"
          onChange={(e) => {
            setNote(e.target.value);
            if (data) {
              onChooseImage({
                file: uploadImage,
                presignedUrl: data.imageUploadPresignedUrl,
                note: e.target.value,
              });
            }
          }}
        />
      </div>
    </FlexRow>
  );
}

export default ImageUploadOne;
