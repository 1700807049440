import { FileTextOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Button, message, Select } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import React from 'react';
import { Paper, Table } from '../Common';
import DateTimePicker from '../Common/DateTimePicker';
import { FlexRow } from '../Common/Styled';

const { Option } = Select;

export const GET_REPORT = gql`
  query ($code: String!, $date: String!) {
    getReport(code: $code, date: $date) {
      headers {
        order
        label
        valueType
      }
      rows {
        index
        columns {
          order
          value
        }
      }
    }
  }
`;

export const GET_REPORTS = gql`
  query {
    reports {
      id
      name
      code
      description
      allowed
    }
  }
`;

interface ValueType extends AiType {
  [x: number]: string;
}

type ColType = {
  dataIndex: string;
  title: string;
};

const Tailan: React.VoidFunctionComponent = () => {
  const [code, setCode] = React.useState<string>();
  const [date, setDate] = React.useState<string | undefined>(
    format(new Date(), 'yyyy-MM'),
  );
  const [cols, setCols] = React.useState<ColType[]>([]);
  const [rows, setRows] = React.useState<ValueType[]>([]);
  const [reports, setReports] = React.useState<Report[]>([]);
  const { loading, data } = useQuery(GET_REPORTS);
  const [getReport, { data: reportSource, error, loading: loadingReport }] =
    useLazyQuery(GET_REPORT, {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache', // Used for subsequent executions
    });

  React.useEffect(() => {
    if (reportSource) {
      const detail = reportSource.getReport as ReportDetail;
      if (detail.headers && detail.rows) {
        const header = detail.headers.map((v) => ({
          title: v.label,
          dataIndex: `${v.order}`,
        }));
        const values = detail.rows.map((r, key) => {
          let bb = { key };
          r.columns.forEach((c) => {
            bb = {
              ...bb,
              [c.order]: c.value,
            };
          });
          return bb;
        });
        setCols(header);
        setRows(values as ValueType[]);
      } else {
        setCols([]);
        setRows([]);
      }
    }
  }, [reportSource, error]);

  React.useEffect(() => {
    if (loading) {
      message.info('Уншиж байна...');
    } else if (data) {
      message.destroy();
      const summaries = data.reports.map((v: Report, i: number) => ({
        ...v,
        key: i,
      })) as Report[];
      setReports(summaries);
    }
  }, [data]);

  return (
    <>
      <Paper>
        <FlexRow style={{ alignItems: 'center' }}>
          <Select
            loading={loading}
            size="large"
            style={{ width: '80%' }}
            onChange={(v) => setCode(`${v}`)}
            placeholder="Тайлан сонгох">
            {reports.map((v: Report, i) => (
              <Option key={i} value={v.code}>
                {v.code} - {v.name}
                <span style={{ marginLeft: 10, fontSize: 13, color: 'grey' }}>
                  {v.description}
                </span>
              </Option>
            ))}
          </Select>
          <DateTimePicker
            defaultValue={dayjs()}
            style={{ width: '20%', margin: '0 15px' }}
            picker="month"
            format="YYYY/MM"
            size="large"
            placeholder="Хугацаа сонгох"
            onChange={(e) => {
              if (e) {
                setDate(format(e?.toDate(), 'yyy-MM'));
              } else {
                setDate(undefined);
              }
            }}
          />
          <Button
            type="primary"
            loading={loadingReport}
            icon={<FileTextOutlined />}
            onClick={() => {
              if (code && date) getReport({ variables: { code, date } });
            }}>
            Тайлан харах
          </Button>
        </FlexRow>
      </Paper>
      <Paper>
        <Table<ValueType>
          columns={cols}
          data={rows}
          pagination={{ pageSize: 20 }}
          onDownload={() => {
            if (cols.length > 0 && rows) {
              const excel = new Excel();
              excel
                .addSheet('Захиалга')
                .setRowHeight(0.5, 'cm')
                .addColumns(cols)
                .setTHeadStyle({ bold: true, border: true })
                .addDataSource(rows)
                .saveAs(`Тайлан-${code}.xlsx`);
            }
          }}
        />
      </Paper>
    </>
  );
};

export default Tailan;
