import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { message } from 'antd';
import { FlexRow } from '../Common/Styled';
import { Label } from '../Common';

export const Padded = styled(FlexRow)`
  padding: 3px 0;
  white-space: nowrap;
`;

const STAFF = gql`
  query {
    staff {
      id
      name
      phone
      position
    }
  }
`;

const StaffList: React.FunctionComponent = () => {
  const { loading, data } = useQuery(STAFF);
  const [staff, setStaff] = useState<Staff[]>([]);

  useEffect(() => {
    let items: Staff[] = [];

    if (loading) {
      message.info('Уншиж байна...');
    } else if (data) {
      message.destroy();
      items = data.staff.map((item: Staff, index: number) => ({
        ...item,
        key: `ci-${index}`,
      }));
    }
    setStaff(items);
  }, [loading, data]);

  return (
    <div>
      <FlexRow>
        <div style={{ flex: 1, marginRight: 20 }}>
          <Label style={{ color: 'red' }}>Холбоо барих</Label>
          {staff.map((s, i) => (
            <div key={i} title={s.phone}>
              <div style={{ display: 'inline-block' }}>{s.phone}</div>
              <div
                style={{
                  paddingLeft: '10px',
                  display: 'inline-block',
                  fontSize: 12,
                  color: 'grey',
                }}>{`${s.name !== '' ? `${s.name} ` : ''} (${
                s.position
              })`}</div>
            </div>
          ))}
        </div>
      </FlexRow>
    </div>
  );
};

export default StaffList;
