import React from 'react';
import { useQuery } from '@apollo/client';
import { add, format, sub } from 'date-fns';
import { message } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Paper } from '../Common';
import { FlexRow } from '../Common/Styled';
import Monthly from '../Dashboard/Monthly';
import MainTable from '../Dashboard/MainTable';
import { GET_SUMMARY } from '../Dashboard';
import { extractByTypes } from '../Common/utils';

const Button = styled.div`
  padding: 5px 0;
  cursor: pointer;
`;

const fmt = (date: Date): string => {
  return format(date, 'yyyy-MM');
};

const Summary: React.VoidFunctionComponent = () => {
  const [summary, setSummary] = React.useState<SummaryType[]>([]);
  const [summed, setSummed] = React.useState<Turul[]>([
    { name: '-', daily: 0, monthly: 0 },
  ]);
  const [months, setMonths] = React.useState<Date[]>([
    sub(new Date(), { months: 2 }),
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);
  const { loading, data, refetch } = useQuery(GET_SUMMARY, {
    variables: { startDate: fmt(months[0]), endDate: fmt(months[2]) },
  });

  React.useEffect(() => {
    refetch();
  }, [months]);

  React.useEffect(() => {
    if (loading) {
      message.info('Уншиж байна...');
    } else if (data) {
      message.destroy();
      const summaries = data.taskSummary as SummaryType[];
      setSummary(summaries);
      const summedByGroup = extractByTypes(summaries);
      setSummed(summedByGroup);
    }
  }, [data]);

  const onPrev = () => {
    setMonths([sub(months[0], { months: 1 }), months[0], months[1]]);
  };

  const onNext = () => {
    setMonths([months[1], months[2], add(months[2], { months: 1 })]);
  };

  return (
    <Paper>
      <FlexRow>
        <MainTable byType={summed} />
        <div>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <Button onClick={onPrev}>
              <ArrowLeftOutlined />
              Өмнөх сар
            </Button>
            <Button onClick={onNext}>
              Дараа сар <ArrowRightOutlined />
            </Button>
          </FlexRow>
          <FlexRow>
            {months.map((month, i) => (
              <Monthly
                key={i}
                title={fmt(month)}
                names={summed.map((v) => v.name)}
                summary={summary[i]}
              />
            ))}
          </FlexRow>
        </div>
      </FlexRow>
    </Paper>
  );
};

export default Summary;
