import { Dayjs } from 'dayjs';
import * as React from 'react';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import DateTimePicker from './DateTimePicker';

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>;

const TimePicker = React.forwardRef<never, TimePickerProps>((props, ref) => {
  return <DateTimePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
