import React from 'react';
import { message } from 'antd';
import { useQuery, gql } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { Paper, Table } from '../Common';

const WORK_CATEGORIES = gql`
  query {
    workCategories {
      id
      name
      shortName
    }
  }
`;

const WorkCategory: React.VoidFunctionComponent = () => {
  const [tasks, setTasks] = React.useState<WorkCategory[]>([]);
  const { data, loading } = useQuery(WORK_CATEGORIES);

  React.useEffect(() => {
    if (loading) {
      message.loading('Уншиж байна...');
    } else if (data) {
      message.destroy();
      const records = data.workCategories.map(
        (item: WorkCategory, index: number) => ({
          ...item,
          key: `ci-${index}`,
        }),
      );
      setTasks(records);
    }
  }, [data]);

  const columns: ColumnsType<WorkCategory> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Товч нэр',
      dataIndex: 'shortName',
    },
    {
      title: 'Дэлгэрэнгүй',
      dataIndex: 'name',
    },
  ];

  return (
    <Paper>
      <div style={{ maxWidth: 800 }}>
        <Table<WorkCategory>
          columns={columns}
          data={tasks}
          pagination={false}
        />
      </div>
    </Paper>
  );
};

export default WorkCategory;
