import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Paper, Table, LabeledInput } from '../Common';
import { FlexRow } from '../Common/Styled';

const GET_STAFF = gql`
  query {
    staff {
      id
      name
      phone
      position
      order
    }
  }
`;

// prettier-ignore
const ADD_STAFF = gql`
  mutation createStaff($name: String, $phone: String!, $position: String, $order: Int) {
    createStaff(input: { name: $name, phone: $phone, position: $position, order: $order }) {
      ok
      message
    }
  }
`;

// prettier-ignore
const EDIT_STAFF = gql`
  mutation updateStaff($id: Int!, $name: String, $phone: String!, $position: String, $order: Int) {
    updateStaff(id: $id, input: { name: $name, phone: $phone, position: $position, order: $order }) {
      ok
      message
    }
  }
`;

const DELETE_STAFF = gql`
  mutation deleteStaff($id: Int!) {
    deleteStaff(id: $id) {
      ok
      message
    }
  }
`;

interface Staff extends AiType {
  name?: string;
  phone: string;
  position?: string;
  order?: number;
}

const Holiday: React.VoidFunctionComponent = () => {
  const [selected, setSelected] = React.useState<Staff>();
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [phone, setPhone] = React.useState<string>();
  const [position, setPosition] = React.useState<string | undefined>();
  const [order, setOrder] = React.useState<number | undefined>();
  const [staff, setStaff] = React.useState<Staff[]>([]);
  const { error: fetchError, data, refetch, loading } = useQuery(GET_STAFF);
  const [mutate, { error: mutationError, loading: mutationLoading }] =
    useMutation(selected ? EDIT_STAFF : ADD_STAFF);
  const [deleteStaff, { error: deleteError }] = useMutation(DELETE_STAFF);

  React.useEffect(() => {
    if (loading) {
      message.loading('Уншиж байна...');
    } else if (fetchError || mutationError || deleteError) {
      message.error('Алдаа гарлаа');
    } else if (data) {
      message.destroy();
      setStaff(data.staff.map((s: Staff) => ({ ...s, key: s.id })));
    }
  }, [loading, data]);

  const clear = () => {
    setName(undefined);
    setPhone(undefined);
    setPosition(undefined);
    setOrder(1);
    setSelected(undefined);
  };

  const onEdit = (s: Staff) => {
    setSelected(s);
    if (s.phone) {
      setPhone(s.phone);
      setName(s.name);
      setPosition(s.position);
      setOrder(s.order);
    }
  };

  const onDelete = (s: Staff) => {
    deleteStaff({ variables: { id: s.id } }).then(() => {
      refetch();
    });
  };

  const onSubmit = () => {
    if (phone) {
      mutate({
        variables: {
          id: selected?.id,
          name,
          phone,
          position,
          order,
        },
      }).then(() => {
        refetch();
        clear();
      });
    }
  };

  const renderActions = (item: Staff) => (
    <FlexRow style={{ justifyContent: 'center' }}>
      <Tooltip title="Засварлах">
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => onEdit(item)}
        />
      </Tooltip>
      <Tooltip title="Устгах">
        <Popconfirm
          placement="bottomLeft"
          title="Уг амралтийн өдрийг устгах уу?"
          onConfirm={() => onDelete(item)}
          okText="Тийм"
          cancelText="Үгүй">
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </Tooltip>
    </FlexRow>
  );

  const columns: ColumnsType<Staff> = [
    {
      title: 'Эрэмбэ',
      dataIndex: 'order',
    },
    {
      title: 'Нэр',
      dataIndex: 'name',
      width: 120,
    },
    {
      title: 'Утас',
      dataIndex: 'phone',
    },
    {
      title: 'Албан тушаал',
      dataIndex: 'position',
    },
  ];

  return (
    <Paper>
      <FlexRow style={{ alignItems: 'flex-top', maxWidth: 1100 }}>
        <FlexRow style={{ flex: 1, flexWrap: 'wrap' }}>
          <Table<Staff>
            columns={columns}
            data={staff}
            action={renderActions}
            pagination={false}
          />
        </FlexRow>
        <div style={{ width: 400 }}>
          <LabeledInput
            label="Нэр"
            value={name}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setName(e.currentTarget.value)
            }
          />
          <LabeledInput
            label="Утас"
            value={phone}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPhone(e.currentTarget.value)
            }
          />
          <LabeledInput
            label="Албан тушаал"
            value={position}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPosition(e.currentTarget.value)
            }
          />
          <LabeledInput
            label="Эрэмбэ"
            value={order}
            type="number"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setOrder(+e.currentTarget.value)
            }
          />
          <FlexRow style={{ justifyContent: 'flex-end', marginTop: 10 }}>
            <Button onClick={clear} style={{ marginRight: 5 }}>
              Цэвэрлэх
            </Button>
            <Button
              type="primary"
              loading={mutationLoading}
              onClick={onSubmit}
              icon={selected ? <EditOutlined /> : <PlusOutlined />}>
              {selected ? 'Засах' : 'Нэмэх'}
            </Button>
          </FlexRow>
        </div>
      </FlexRow>
    </Paper>
  );
};

export default Holiday;
